.header-nav-secondary {
  margin: 1em 0;
  padding: 1em 0;
  list-style: none;
  column-count: 2;
  border: solid darken(@blue-dark, 15%);
  border-width: 2px 0;
}
.header-nav-secondary__item {
  display: block;
  // accommodate focus styling because of the hidden overflow:
  padding: (@focus[width] + @focus[offset]);
  overflow: hidden;
  break-inside: avoid;
}
.header-nav-secondary__link {
  display: block;
  padding: 0.25em 0.35em;
  text-decoration-color: transparent;

  &:hover,
  &:focus,
  &:active {
    text-decoration-color: hsla(0, 0%, 100%, 0.65);
  }
}



@media screen and @bp[header-lg] {
  .header-nav-secondary__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 1.25rem;
    padding-right: @side-spacing;
    font-size: 0.8em;
    text-align: right;
  }
  .header-nav-secondary {
    margin: 1.5rem 0 0;
    padding: 0;
    column-count: 1;
    border-width: 0;

    // Older versions of iOS seem to require the following
    // in order to register link clicks properly in this context
    // and I blame CSS columns entirely
    -webkit-perspective: 1;
  }
  .header-nav-secondary__item {
    display: inline-block;
    overflow: visible;
    margin-left: 1em;
    padding: 0;
    
    &:first-child {
      margin-left: 0;
    }
  }
  .header-nav-secondary__item--hide-lg {
    display: none;
  }
  .header-nav-secondary__link {
    padding: 0.125em 0.25em;
    color: @grey-dark;
    text-decoration-color: @grey4;
    
    &:hover,
    &:focus,
    &:active {
      text-decoration-color: @grey5;
    }
  }
}
