.site-footer {
  flex: 0 0 auto;
  font-size: 0.85em;
  line-height: @line-height[body] + 0.1;
  padding: 3em 0 2em;
  color: white;
  background-color: @grey-darker;
  box-shadow: inset 0 0.8em 2em -1em hsla(0, 0%, 0%, 0.35);

  a {
    display: inline-block;
    padding: 0.15em 0;
    text-decoration-color: transparent;

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: @grey4;
    }
  }
}

.site-footer__nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  align-items: baseline;
  width: 100%;
}
.site-footer__nav {
  flex: 1 1 auto;
  margin: 0 0 @global-margin-bottom 0;
  width: auto;
  list-style: none;
}
.site-footer__nav-item {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 1.5em;
}
.site-footer__nav-link {
  .site-footer & {
    display: block;
    padding: 0.4em 0 0.3em;
  }
}

.site-footer__c3p {
  text-align: center;
}
.site-footer__c3p-link {
  display: inline-block;
  vertical-align: middle;
  margin: 0.5em 0.5em 0.15em;
}
.site-footer__c3p-logo {
  width: 17em;
  height: 2.55em;
  max-width: 100%;
}



@media screen and (min-width: 40em) {
  .site-footer {
    a {
      padding: 0.1em 0;
    }
  }
  .site-footer__nav-wrapper {
    flex-wrap: nowrap;
  }
}
