// Section nav
//
// Secondary navigation toggled by a button at small viewport sizes, and occupies a left-hand column at large viewport sizes.
// The section nav uses the [collapser](/styleguide/section-global/#kssref-global-collapser) styling and script to handle how it
// shows/hides sublist items, and itself at small viewport sizes.
//
// Style guide: components.section-nav

@section-nav-margin-bottom: 1.5rem;
@section-nav-line-position: 1rem;

@section-nav-colours: {

  // a lot of blue

  link1-static: #01416b;
  link1-active: darken(#01416b, 10%);
  border1: lighten(#01416b, 5%);

  link2-static: #005187;
  link2-active: darken(#005187, 10%);
  border2: lighten(#005187, 5%);

  link3-static: @blue-dark;
  link3-active: darken(@blue-dark, 10%);
  border3: lighten(@blue-dark, 5%);

  link4-static: #0068a2;
  link4-active: darken(#0068a2, 10%);
  border4: lighten(#0068a2, 5%);

  link5-static: @blue;
  link5-active: darken(@blue, 10%);
  border5: lighten(@blue, 5%);

  link6-static: #158bc6;
  link6-active: darken(#158bc6, 10%);
  border6: lighten(#158bc6, 5%);

  link7-static: #339dd6;
  link7-active: darken(#339dd6, 10%);
  border7: lighten(#339dd6, 10%);

  // original

  // link1-static: @grey-darker;
  // link1-active: darken(@grey-dark, 20%);
  // border1: @grey5;

  // link2-static: @red-dark;
  // link2-active: darken(@red-dark, 10%);
  // border2: lighten(@red, 10%);

  // link2-static: @grey5;
  // link2-active: darken(@grey5, 10%);
  // border2: lighten(@grey4, 10%);

  // link3-static: @blue-dark;
  // link3-active: darken(@blue-dark, 10%);
  // border3: @blue;

  // link4-static: @blue;
  // link4-active: darken(@blue-dark, 5%);
  // border4: lighten(@blue, 10%);

  // link4-static: @grey5;
  // link4-active: @grey-darker;
  // border4: @grey4;

}


.section-nav__container {
  position: relative;
  z-index: @z[section-nav];
  width: 100%;
  height: 100%;
  margin-top: -0.5em;
  margin-bottom: @section-nav-margin-bottom;

  &::after {
    position: absolute;
    z-index: -1;
    display: block;
    content: '';
    left: 0;
    right: 0;
    margin: 0 calc(-50vw + 50%);
    bottom: @section-nav-line-position;
    height: 2px;
    background-color: @blue;
  }
}
.section-nav {
  margin: 0 auto;
  max-width: 26em;
}
.section-nav__list {
  position: relative;
  margin: 0.5em auto 0;
  padding: 3px 0.5em 0;
  font-family: @fonts[heading];
  line-height: (@line-height[heading] + 0.1);
}

.section-nav__list-toggle {
  position: relative;
  z-index: 2;
  display: none;
  font-size: 0.9em;
  margin-bottom: -0.25em;
  padding: 0.65em;
  width: 100%;
  letter-spacing: 0.01em;

  .has-js & {
    display: block;
  }
}

.section-nav__item {
  margin-bottom: 2px;
}

.section-nav__link-wrapper {
  display: flex;
}
.section-nav__link {
  display: block;
  font-size: 1.35em;
  padding: 0.45rem 1rem 0.6rem;
  flex: 1 1 auto;
  -ms-flex-negative: 1;
  max-width: 100%;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-decoration-color: currentColor;

  // color and background-color declared below along with
  // .section-nav__toggle

  &:extend(.no-box-shadow);

  &[aria-current] {
    text-decoration: underline;
    text-decoration-color: currentColor;
    text-decoration-thickness: 2px;
  }
  &:not(.btn):focus {
    outline: @focus[width] solid @focus[colour];
    outline-offset: 0;
    text-decoration: underline;
    text-decoration-color: currentColor;
    text-decoration-thickness: 2px;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: darken(@grey-dark, 20%);
    &:extend(.no-box-shadow);
  }

  // Mainly for ie9; flexbox-enabled browsers will ignore this
  &:not(:only-child) {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - (2em + 4px));
  }
}
.section-nav__toggle {
  display: none;
  padding: 0.6em;
  flex: 0 0 auto;

  font-weight: bold;
  text-align: center;
  border-left: 2px solid @section-nav-colours[border7];
  border-radius: 0;

  .has-js & {
    display: inline-block;
  }

  .section-nav__item:nth-of-type(7n + 2) & {
    border-left-color: @section-nav-colours[border2];
  }
  .section-nav__item:nth-of-type(7n + 3) & {
    border-left-color: @section-nav-colours[border3];
  }
  .section-nav__item:nth-of-type(7n + 4) & {
    border-left-color: @section-nav-colours[border4];
  }
  .section-nav__item:nth-of-type(7n + 5) & {
    border-left-color: @section-nav-colours[border5];
  }
  .section-nav__item:nth-of-type(7n + 6) & {
    border-left-color: @section-nav-colours[border6];
  }
  section-nav__item:nth-of-type(7n + 7) & {
    border-left-color: @section-nav-colours[border7];
  }
}
.section-nav__link,
.section-nav__toggle {
  background-color: @section-nav-colours[link1-static];
  color: white;

  &:hover,
  &:focus,
  &:active {
    background-color: @section-nav-colours[link1-active];
  }

  .section-nav__item:nth-of-type(7n + 2) & {
    background-color: @section-nav-colours[link2-static];

    &:hover,
    &:focus,
    &:active {
      background-color: @section-nav-colours[link2-active];
    }
  }
  .section-nav__item:nth-of-type(7n + 3) & {
    background-color: @section-nav-colours[link3-static];

    &:hover,
    &:focus,
    &:active {
      background-color: @section-nav-colours[link3-active];
    }
  }
  .section-nav__item:nth-of-type(7n + 4) & {
    background-color: @section-nav-colours[link4-static];

    &:hover,
    &:focus,
    &:active {
      background-color: @section-nav-colours[link4-active];
    }
  }
  .section-nav__item:nth-of-type(7n + 5) & {
    background-color: @section-nav-colours[link5-static];

    &:hover,
    &:focus,
    &:active {
      background-color: @section-nav-colours[link5-active];
    }
  }
  .section-nav__item:nth-of-type(7n + 6) & {
    background-color: @section-nav-colours[link6-static];

    &:hover,
    &:focus,
    &:active {
      background-color: @section-nav-colours[link6-active];
    }
  }
  .section-nav__item:nth-of-type(7n + 7) & {
    background-color: @section-nav-colours[link7-static];

    &:hover,
    &:focus,
    &:active {
      background-color: @section-nav-colours[link7-active];
    }
  }
}
.section-nav__toggle-icon {
  fill: white;

  .starts-open & .v,
  .is-active > & .v,
  [aria-expanded="true"] > & .v {
    display: none;
  }
}

.section-nav__sublist {
  display: block;
  margin: 0;
  padding-left: 1rem;
  font-size: 1.2em;
  list-style: none;
}
.section-nav__subitem {
  margin-bottom: 0.5em;

  &:first-of-type {
    margin-top: 0.65em;
  }
  &:last-of-type {
    margin-bottom: 1.25em;
  }
}
.section-nav__sublink {
  display: inline-block;
  font-size: 1.05em;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: @grey6;

  &:hover,
  &:active,
  &:focus,
  &[aria-current] {
    color: darken(@grey-darker, 10%);
  }
  &[aria-current] {
    text-decoration: underline;
    text-decoration-color: darken(@grey-darker, 10%);
    text-decoration-thickness: 2px;
  }
}



@media screen and @bp[sidenav-lg] {
  .section-nav__column {
    // The following redundancies are thanks to ie10-11
    width: 15.5em;
    width: calc(13em + 2.5 * ((100vw - 47em) / 50));
    max-width: 15.5em;
    max-width: calc(13em + 2.5 * ((100vw - 47em) / 50));
  }
  .section-nav__container {
    margin-top: 0;
    padding-right: 2em;
    padding-right: calc(1.5em + 1 * ((100vw - 47em) / 50));

    &::after {
      content: none;
    }
  }

  .section-nav__list {
    margin: 0 -(@focus[width] + @focus[offset]);
    padding: (@focus[width] + @focus[offset]);

    // .section-nav__list should never be hidden at this
    // viewport size, so override .collapser declarations
    .has-js & {
      max-height: none;
      visibility: visible;
    }
  }
  .has-js .section-nav__list-toggle {
    display: none;
  }

  .section-nav__sublist {
    padding-right: 4px;
  }
}


@supports (position: sticky) {
  @media screen and @bp[sidenav-lg] {
    .section-nav {
      position: sticky;
      top: 1em;
    }
  }
}
