.c-donate-banner {
    background-color: #000;
    color: #fff;

}
.c-donate-banner__text {
    max-width: 33em;
    a {
      color: #fff;
    }

    .btn--white {
      .btn-colours(white; hsl(0, 0%, 85%); hsl(0, 0%, 80%); @grey-darker);
    }
}



@media screen and (min-width: 40em) {
    .c-donate-banner {
        background-image: url('@{image-path}content/front-page/DONATE_footerimage.jpg');
        background-repeat: no-repeat;
        background-position: right -8em center;
        background-size: cover;
    }
    .c-donate-banner__text {
        width: 50%;
    }
}
@media screen and @bp[header-lg] {
    .c-donate-banner {
        background-position: right -4em center;
    }
}
@media screen and (min-width: 65em) {
    .c-donate-banner {
        padding: 2em 0 1em;
        background-position: 75% center;
        background-size: contain;
    }
    .c-donate-banner__text {
        width: 44%;
    }
}
@media screen and (min-width: 110em) {
    .c-donate-banner {
        background-position: 60% center;
    }
}
