.c-info-boxes {
  margin: 1em 0 2em;
}
.c-info-box {
  display: inline-block;
  vertical-align: top;
  font-size: 0.85em;
  width: 100%;
  margin-top: 2em;

  & :last-child {
    margin-bottom: 0;
  }
}
.c-info-box__img {
  display: block;
  margin-bottom: 0.7em;
  width: 100%;
  padding-bottom: 60%;
  background-color: @grey2;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.c-info-box__heading {
  font-family: @fonts[heading];
  // font-size: 1.4em;
  margin-bottom: 0.75em;
  line-height: @line-height[heading] + 0.2;
  letter-spacing: 0.02em;
  color: @blue-dark;
}
.c-info-box__subheading {
  display: block;
  margin-top: 0.3em;
  font-family: @fonts[body];
  font-size: 0.75em;
  font-weight: normal;
  color: @grey6;
}



@media screen and (min-width: 40em) {
  .c-info-box {
    width: 49%;
    padding-right: 1.5em;
  }
  .c-info-box--full-width {
    width: 100%;
  }
}



@supports (display: grid) {
  .c-info-boxes {
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
    margin: 2em 0;
  }
  .c-info-box {
    width: auto;
    margin-top: 0;
    padding: 0;
  }
  .c-info-box--full-width {
    grid-column: ~'1 / -1';
  }
}
