// Fancy heading
//
// Style guide: components.fancy-heading


@fancy-heading-top-spacing: (@section-nav-margin-bottom + @section-nav-line-position);

.fancy-heading {
  position: relative;
  margin: -(@fancy-heading-top-spacing) -1em 2rem;
  margin: -(@fancy-heading-top-spacing) calc(-50vw + 50%) 2rem;
  padding: @fancy-heading-top-spacing 1rem 0;
  padding: @fancy-heading-top-spacing @side-spacing 0;
}
.fancy-heading--with-img {
  padding-bottom: 1.5em;
}
.fancy-heading__inner {
  margin: 0;
}
.fancy-heading__text {
  position: relative;
  line-height: (@line-height[heading] - 0.05);
}
.fancy-heading__parent-name {
  display: block;
  margin-bottom: 0.2em;
  letter-spacing: 0.015em;
  font-size: 0.6em;
  font-weight: normal;
}
.fancy-heading__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: darken(@blue-dark, 5%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: @box-shadow[inset-lg-top];
}


// ------------------------------------------------------------------
// Page-specific text/image declarations, by section where applicable
//

.fancy-heading__logo--alerts {
  display: block;
  margin-top: 2.5em;
  width: 18em;
  width: calc(18em + 4vw);
  max-width: 24em;
  // filter: drop-shadow(0.1em 0.1em 0.25em white);
}

// .fancy-heading__img--alerts {
//   background-image: url('@{image-path}content/online-harms/alerts_header.jpg');
//   background-image:
//     linear-gradient(to right, hsla(0, 0%, 100%, 0.9) 30%, transparent 60%),
//     url('@{image-path}content/online-harms/alerts_header.jpg');
//   background-position: center, left 30%;
// }

.fancy-heading__img--alerts {
  background-image: url('@{image-path}content/online-harms/alerts_header.jpg');
}

.fancy-heading__logo--alerts-home {
  display: block;
  width: 20em;
  width: calc(20em + 4vw);
  max-width: 40em;
}

// --------
// About Us
//

.fancy-heading__text--about {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--about {
  background-image: url('@{image-path}content/about/about_header.jpg');
}

.fancy-heading__text--partners {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--partners {
  background-image: url('@{image-path}content/about/partners_header.jpg');
}

.fancy-heading__text--history {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--history {
  background-image: url('@{image-path}content/about/history_header.jpg');
}

// --------
// CSA
//

.fancy-heading__text--csa {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--csa {
  background-image: url('@{image-path}content/csa/csa_header.jpg');
}

.fancy-heading__text--csam {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--csam {
  background-image: url('@{image-path}content/csa/csam_header.jpg');
}

.fancy-heading__text--arachnid {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}

.fancy-heading__text--support {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--support {
  background-image: url('@{image-path}content/csa/support_header.jpg');
}

.fancy-heading__text--grooming {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--grooming {
  background-image: url('@{image-path}content/csa/grooming_header.jpg');
}

.fancy-heading__text--duty {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--duty {
  background-image: url('@{image-path}content/csa/duty_header.jpg');
}

// --------
// Online Harms
//

.fancy-heading__text--harms {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--harms {
  background-image: url('@{image-path}content/online-harms/online_harms_header.jpg');
}

.fancy-heading__text--images {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--images {
  background-image: url('@{image-path}content/online-harms/images_header.jpg');
}

.fancy-heading__text--luring {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--luring {
  background-image: url('@{image-path}content/online-harms/luring_header.jpg');
}

.fancy-heading__text--resources {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--resources {
  background-image: url('@{image-path}content/online-harms/resources_header.jpg');
}

.fancy-heading__text--sextortion,
.fancy-heading__text--deepfakes  {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--sextortion {
  background-image: url('@{image-path}content/online-harms/sextortion_header.jpg');
}
.fancy-heading__img--deepfakes {
  background-image: url('@{image-path}content/online-harms/ai-and-deepfakes_header.jpg');
}

.fancy-heading__text--violence {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--violence {
  background-image: url('@{image-path}content/online-harms/sexualviolence_header.jpg');
}

// --------
// Research
//

.fancy-heading__text--research {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--research {
  background-image: url('@{image-path}content/research/research_header.jpg');
}

// --------
// Campaigns & Media
//

.fancy-heading__text--campaigns_media {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--campaigns_media {
  background-image: url('@{image-path}content/public-awareness/campaigns_media_header.jpg');
}

.fancy-heading__text--sid {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 100%, 100%, 1),
    0.02em 0.05em 0.25em hsla(0, 100%, 100%, 1);
}
.fancy-heading__img--sid {
  background-image: url('@{image-path}content/public-awareness/sid_header.jpg');
}

.fancy-heading__text--ctip {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--ctip {
  background-image: url('@{image-path}content/public-awareness/ctip_header.jpg');
}

.fancy-heading__text--media {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--media {
  background-image: url('@{image-path}content/public-awareness/media_header.jpg');
}

// --------
// Reporting
//

.fancy-heading__text--action {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--action {
  background-image: url('@{image-path}content/reporting/action_header.jpg');
}

.fancy-heading__text--types {
  margin-top: 3.5rem;
  margin-top: calc(2rem + 10vh);
  margin-bottom: 0.5rem;
  text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
}
.fancy-heading__img--types {
  background-image: url('@{image-path}content/reporting/types_header.jpg');
}

// --------
// Arachnid 5 year anniversary
//

.fancy-heading__img--arachnid {
  background-image: url('@{image-path}content/csa/arachnid_header.jpg');
}
.fancy-heading__arachnid-logo {
  width: 200px;
  height: auto;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

// add model disclaimer

.image-text--alerts {
  .model-sg-b();
}

.image-text--about,
.image-text--csa {
  .model-sg-w();
}

.image-text--sid {
  .model-pl-b();
}

.image-text--resources {
  .model-pl-w();
}

// --------

@media screen and @bp[header-lg] {
  .fancy-heading {
    margin-left: 0;
    margin-right: 0;
    margin-top: -(@fancy-heading-top-spacing + 0.2em);
    margin-top: calc(-(@fancy-heading-top-spacing) + (@site-header-border-width / 1.5));
    padding-left: 0;
    padding-right: 0;
  }
  .fancy-heading--with-img {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .fancy-heading__arachnid-logo {
    width: 100px;
  }
}
