@nav-link-side-padding: 0.4em;

.header-nav-primary {
  margin: 1em 0 0;
  list-style: none;
  font-size: 1.8em;
  font-size: calc(1.5em + 1.3 * ((100vw - 600px) / (1000 - 600)));
  font-family: @fonts[heading];
  line-height: @line-height[heading] + 0.05;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
}
.header-nav-primary__item {
  display: inline-block;
  margin: 0.125em 0;
  width: 100%;
  max-width: 100%;
}
.header-nav-primary__link {
  display: inline-block;
  padding: 0 @nav-link-side-padding 0.35em;
  text-decoration-thickness: 0.125em;

  // silly specificity override which is my own fault
  &:not(.btn) {
    outline-offset: 0;
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration-color: white;
  }
}

// .header-nav-primary__item--report {
  // margin: 0.65em 0;
  // text-align: center;
// }
// .header-nav-primary__item--report {
  // position: relative;
  // padding: 0.5em 1.35em 0.6em 0.85em;
  // font-size: 1.8rem;
  // font-family: @fonts[heading];
  // line-height: @line-height[heading];
  // font-weight: bold;
  // font-style: normal;
  // letter-spacing: 0.02em;
  // text-transform: uppercase;
  // text-decoration: underline;
  // text-decoration-thickness: 2px;
  // text-decoration-color: transparent;
  // border: 0.1em solid darken(@red-dark, 10%);
  // background-color: @red;

  // &:hover,
  // &:focus,
  // &:active {
    // background-color: lighten(@red, 5%);
    // text-decoration-color: currentColor;

    // .header-nav-primary__report-icon {
      // transform: rotate(10deg) scale(1.1);
    // }
  // }
// }
// .header-nav-primary__report-icon {
  // position: absolute;
  // display: block;
  // top: 17%;
  // right: 0.65em;
  // height: 70%;
  // width: 0.5em;
  // fill: white;
  // transform: rotate(10deg);
  // transition: transform @t[basic] ease-in-out;
// }



@media screen and (min-width: 30em) {
  .header-nav-primary__item {
    text-align: left;
  }
}
@media screen and @bp[header-lg] {
  .header-nav-primary {
    display: flex;
    // offset link's side padding:
    margin: 0.85em 0 0 -@nav-link-side-padding;
  }
  .header-nav-primary__item {
    flex: 0 1 auto;
    width: auto;
    margin: 0 0.25em 0 0;
    align-self: stretch;
  }
  .header-nav-primary__link {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 0.5em;
    height: 100%;
    color: @blue-dark;
    word-break: keep-all;
    text-align: center;
    text-decoration: none;

    &::after {
      position: absolute;
      display: block;
      content: '';
      font-size: 1em;
      bottom: -1px;
      left: 50%;
      margin-left: -0.4em;
      border: 0.4em solid transparent;
      border-bottom-color: @blue;
      background-color: transparent;

      opacity: 0;
      transform: translateY(0.2em);
      transition: opacity @t[snappy], transform @t[snappy];
    }

    &:hover,
    &:focus,
    &:active,
    &.is-current {
      color: @blue;

      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }


//Red REPORT nav (see base.html line 229)

.header-nav-primary__report-link {
  color: @red;

  &:hover,
  &:focus,
  &:active,
  &.is-current {
    color: @red-light;
  }
}


  // .header-nav-primary__item--report {
    // flex: 1 0 auto;
    // margin: 0;
    // text-align: right;
  // }
  // .header-nav-primary__link--report {
    // // compensate for gaps due to rounding:
    // margin-bottom: -1px;
    // padding-bottom: 0.5em;
    // padding-bottom: calc(0.5em + 1px);
    // color: white;
    // text-decoration: underline;
    // text-decoration-thickness: 2px;
    // text-decoration-color: transparent;
    // border-width: 0 0 1px;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    // border-color: @blue;
    // background-color: darken(@red, 5%);
    //
    // &::before,
    // &::after {
      // content: none;
    // }
    //
    // &:hover,
    // &:focus,
    // &:active {
      // color: white;
      // background-color: lighten(@red, 5%);
      // text-decoration-color: currentColor;
      // transform: translateX(0);
//
      // &::before,
      // &::after {
        // content: none;
      // }
    // }
  // }
}



@supports (display: grid) {
  @media screen and (min-width: 30em) and @bp[header-sm] {
    .header-nav-primary {
      margin-bottom: 1em;
      display: grid;
      grid-gap: 0.25em;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    .header-nav-primary__item {
      margin: 0;
      grid-column: 2;
      text-align: left;
      justify-content: center;
    }
    .header-nav-primary__item--report {
      // for visual alignment with secondary nav columns:
      padding-left: calc((@focus[width] + @focus[offset]) + 0.35rem);
      grid-column: 1;
      grid-row: span 3;
      align-self: center;
    }
  }
}
