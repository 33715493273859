.releases-list__item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 2em 0;
    border: solid @line-grey;
    border-width: 0 0 1px;

    .releases-list--landing &,
    &.releases-list__item--first {
        border-width: 0;
    }
}
.releases-list__item--first {
    padding: 2em 1em;
    padding: 2em @side-spacing;
    margin: 0 ~'calc(-50vw + 50%)';
    background-color: @blue-dark;
    color: #fff;
}

.releases-list__title {
    .h3;
}
.releases-list__title--first {
    .h2;
    color: #fff;

    & .releases-list__title-link {
        color: #fff;
    }
}

.releases-list__title-link {
  text-decoration: none;
}


.releases-list__date {
    font-family: @fonts[heading];
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.releases-list__blurb {
    font-size: 0.85em;
}
.releases-list__blurb--first {
    font-size: 1em;
}



@media screen and (min-width: 42em) {
    .releases-list__item {
        width: 45%;
        width: ~'calc(50% - 1.2em)';

        &:nth-of-type(2n) {
            margin-right: 2em;
        }
    }
    .releases-list__item--first {
        width: 100%;
        margin: 0;
    }
}



@supports (display: grid) {
    .releases-list {
        display: grid;
        grid-gap: 2em;
        grid-template-columns: 1fr;
    }
    .releases-list__item {
        width: auto;
        padding: 0.5em 0 1.5em;
    }
    .releases-list__item--first {
        grid-column: ~'1 / -1';
        padding: 2em 1em;
    }



    @media screen and (min-width: 42em) {
        .releases-list {
            grid-template-columns: 1fr 1fr;
        }
        .releases-list__item {
            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }
    }
}
