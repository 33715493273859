//
// Fun fact: CSS multi-column layout is pretty great for flowing inline content,
// and a little bit horrible for flowing block content. It's still the best
// solution to the particular problem of filling columns without having to specify
// a height for the parent element.
//
// If the children elements you're flowing into columns are blocks and you don't
// want them to break between columns:
//
// 1. Use only padding for the containing element (.site-map__category here), no margins
// 2. Make sure to declare both of these on the children elements:
//
//     break-inside: avoid; (autoprefixer will fill in the rest in this case)
//     overflow: hidden; (or else you will have Firefox woes)
//

.site-map {
  margin: 1em 0 2em;
  padding: 2em 0 0.5em;
  border: solid @grey4;
  border-width: 1px 0;
}
.site-map__category {
  padding-bottom: 1.5rem;
}
.site-map__category-heading {
  margin: 0;
  font-size: 2.25em;
  letter-spacing: 0.01em;
  font-weight: normal;
}
.site-map__category-list {
  margin: 0.8em 0 0;
  list-style: none;
}
.site-map__item {
  padding-bottom: 0.1em;

  a {
    text-indent: -0.8em;
    padding-left: 0.8em;
  }
}



@media screen and (min-width: 26em) {
  .site-map {
    column-count: 2;
  }
  .site-map__category {
    display: block;
    margin-left: -4px;
    padding: 6px 0.1em 1.6rem 4px;
    width: auto;
    overflow: hidden;
    break-inside: avoid;
  }
}
@media screen and (min-width: 40em) {
  .site-map {
    column-count: 3;
  }
}
@media screen and (min-width: 60em) {
  .site-map {
    column-count: 4;
  }
}
