// Button
//
// Markup:
// <button class="btn {{modifier_class}}">Button Element</button>
// <a href="#" class="btn {{modifier_class}}">Link Button</a>
//
// .btn--light    - light-coloured button
// .btn--white    - white button
// .btn--dark     - dark-coloured button
// .btn--primary  - primary button, same as default styling
// .btn--warning  - `.btn--report` is an alternate class for this
// .btn--display  - use heading font family
// .btn--sm       - small size
// .btn--lg       - large size
// .btn--full     - full width
// :hover  - highlight the button when hovered
// :focus  - give the button focus styles
// :active - “press” the button down when clicked
//
// Style guide: components.button

.btn {
  position: relative;
  display: inline-block;
  padding: 0.5em 1.125em;
  line-height: @line-height[body];
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-style: italic;
  border-width: 0;
  border-radius: @btn-border-radius;
  background-image: none;

  box-shadow: none;
  color: white;
  background-color: @blue-dark;
  transform: translateY(0);

  &:extend(.link-transitions);

  &:hover {
    box-shadow: 0 0.05em 0.15em hsla(0, 0%, 20%, 0.2);
    background-color: darken(@blue-dark, 10%);
  }
  &:focus {
    box-shadow: 0 0.05em 0.15em hsla(0, 0%, 20%, 0.2);
    outline-color: @focus[colour];
    box-shadow:
       3px  3px 0 @focus[colour],
      -3px -3px 0 @focus[colour],
       3px -3px 0 @focus[colour],
      -3px  3px 0 @focus[colour];
  }
  &:active {
    box-shadow: 0 0.05em 0.15em hsla(0, 0%, 20%, 0.2);
    transform: translateY(0.05em);
  }

  &[target="_blank"]:not(.u-no-pseudo) {
    &::after {
      font-size: 0.85em;
      margin-left: 0.4em;
      margin-right: -0.2em;
    }
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// SIZES
//

.btn--sm {
  font-size: 0.85em;
}
.btn--lg {
  font-size: 1.2em;
}
.btn--full {
  width: 100%;
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// OTHER COSMETIC
//

.btn--display {
  font-family: @fonts[heading];
  font-style: normal;
  letter-spacing: 0.015em;
}

// in case there are icons going on and you do not want
.btn--simple,
.btn--simple[target="_blank"] {
  padding: 0.5em 1.125em;
  background-image: none;

  &::before,
  &::after {
    content: none;
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------------
// COLOURS
//

// colour mixins
.btn-colours(@bg-static; @bg-hover; @bg-active; @text-colour: #fff;) {
  background-color: @bg-static;
  color: @text-colour;

  > span {
    color: @text-colour;
  }

  &:hover {
    background-color: @bg-hover;
  }
  &:focus {
    background-color: @bg-hover;
  }
  &:active {
    background-color: @bg-active;
  }
}

.btn--white {
  .btn-colours(white; hsl(0, 0%, 85%); hsl(0, 0%, 80%); @grey-darker);
}
.btn--light {
  .btn-colours(@blue; @blue-dark; @blue-dark);
}
.btn--dark {
  .btn-colours(@grey-darker; darken(@grey-darker, 15%); darken(@grey-darker, 15%));
}

// .btn--orange {
//   .btn-colours(#cb831a; @orange-dark; @orange-dark);
//
//   &:focus {
//     outline-color: @blue-dark;
//     box-shadow:
//        3px  3px 0 @blue-dark,
//       -3px -3px 0 @blue-dark,
//        3px -3px 0 @blue-dark,
//       -3px  3px 0 @blue-dark;
//   }
// }

.btn--primary {
  .btn-colours(@blue-dark; darken(@blue-dark, 10%); darken(@blue-dark, 10%));
}
// .btn--success {
//   .btn-colours(hsl(100, 80%, 30%); hsl(100, 80%, 25%); hsl(100, 80%, 25%));
// }
.btn--report,
.btn--warning {
  .btn-colours(@red; @red-dark; @red-dark);
}
