@media screen and @bp[sidenav-lg] {
  .l-page-columns {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .l-page-column {
    display: table-cell;
    vertical-align: top;
    padding: 0;
    height: 100%;
    max-width: 100%;
    
    &:only-child {
      display: block;
      padding: 1rem 1rem;
      padding: 1rem @side-spacing;
      margin-left: -1rem;
      margin-right: -1rem;
      margin-left: @side-spacing-neg;
      margin-right: @side-spacing-neg;
      background-color: white;
    }
  }
  .l-page-column--aside {
    width: 32%;

    .l-page-column + & {
      padding-left: @u-lg-sp;
    }
    & + .l-page-column {
      padding-right: @u-lg-sp;
    }
  }
}
