.l-dl-table {
  dd {
    margin-left: 0;
  }
}



@media screen and (min-width: 24em) {
  .l-dl-table {
    display: table;
    
    dt {
      display: table-cell;
      padding-bottom: 0.5em;
    }
    dd {
      display: table-cell;
      padding-left: 0.75em;
      padding-bottom: 0.5em;
    }
  }
  .l-dl-table__row {
    display: table-row;
  }
}
