.sextortion-icons {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  list-style: none;
  gap: 1px;
  background-color: @grey3;

}
.sextortion-icon__item {
  flex: 1 1 13rem;
  padding: 1.25rem;
  text-align: center;
  background-color: white;
  font-family: @fonts[heading];
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: bold;
  text-wrap: balance;

  abbr:hover{
    color: @red;
  }

  .highlight {
    font-size: 1.4em;
    line-height: 1;
    color: @red;
    text-transform: uppercase;
  }
  small {
    display: block;
    margin-top: 0.8rem;
    font-size: 0.7em;
    color: @grey6;
  }
}
.sextortion-icon {
  display: block;
  margin: 0 auto 0.25rem;
  max-width: 10rem;
  max-height: 10rem;
}
.sextortion-note {
  font-size: 0.7em;
}
