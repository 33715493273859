// Modal/dialog
//
// The macro `m.modal_base()` is a base for more specific kinds of modals to extend, and uses a customized fork* of the
// [a11y-dialog script](https://a11y-dialog.netlify.app/).
// It’s set up to work with the macro `m.modal_trigger()`, which creates a button that can show the modal when selected.
//
// See the section on [definition modals](#kssref-components-definition-modal) for a specific example of how the modal base and its trigger
// button can be used.
//
// <small>
// * Although the fork’s customizations aren’t relevant for this component, having been made specifically for an edge case with the
// <a href="/styleguide/section-components/#kssref-components-nav-menu">nav menu component</a>.
// </small>
//
// ### Using the modal base and trigger button macros
// `m.modal_base()` accepts two arguments:
// ```jinja2
// [% raw %][[ m.modal_base(id, starts_open) -]][% endraw %]
// ```
// * `id`: **Required.** Must match the value of `id` set in the corresponding `m.modal_trigger()`.
// * `starts_open`: Optional. Defaults to `false`. When set to `true`, the modal does not automatically show on page load because this
// feature is not implemented yet! But when it is, it’s still going to be best not to use it anyway unless there’s an extremely good reason
// that cannot be accomplished by other means.
//
// `m.modal_trigger()` accepts five arguments:
// ```jinja2
// [% raw %][[ m.modal_trigger(id, btn_text, aria_label, classes, icon_classes) -]][% endraw %]
// ```
// * `id`: **Required.** Must match the value of `id` set in the corresponding `m.modal_base()`.
// * `btn_text`: Optional. Defaults to an empty string, although you almost certainly want some sort of button text.
// * `aria_label`: Optional. Defaults to an empty string. **This must be used** to provide descriptive text to a screen reader or other
// assistive device if no `btn_text` is included, for example if you’re calling this macro with only an icon as its content.
// * `classes`: Optional. Defaults to an empty string. Add CSS classes here as you see fit to style the button.
// * `icon_classes`: Optional. Defaults to the utility class `u-fill--blue-dark`. This is mainly here as a convenience option for extending
// `m.modal_trigger()` in macros that use SVG icons, like the trigger button for the
// [definition modal](#kssref-components-definition-modal).
//
// ### Example usage
//
// ```html
// [% raw -%]
// [[ m.modal_trigger(
//   'example',
//   btn_text='Open the example modal'
// ) ]]
//
// [% call m.modal_base(
//   'example'
// ) %]
//   <div class="modal__body">
//     <div class="modal__body-inner">
//       <p>Hi, I’m an example of a basic modal!</p>
//     </div>
//   </div>
//   <div class="modal__response u-text-right">
//     <button
//       type="button"
//       class="btn btn--primary"
//       data-action="click->modal#close">
//       Close
//     </button>
//   </div>
// [% endcall %]
// [% endraw -%]
// ```
//
// Markup:
// <a
//   href="#dialog-example"
//   data-controller="modal-trigger"
//   data-trigger="dialog-example"
//   data-action="click->modal-trigger#triggerModal keydown->modal-trigger#triggerModal"
//   class="btn"
//   role="button"
//   tabindex="0">
//   Open the example modal
// </a>
// <div
//   class="modal__container"
//   id="dialog-example"
//   aria-hidden="true"
//   data-controller="modal">
//   <div data-a11y-dialog-hide></div>
//   <div
//     class="modal__content"
//     data-modal-target="content"
//     role="document"
//     tabindex="0"
//     style="outline:0">
//     <div class="modal__body">
//       <div class="modal__body-inner">
//         <p>Hi, I’m an example of a basic modal!</p>
//       </div>
//     </div>
//     <div class="modal__response u-text-right">
//       <button
//         type="button"
//         class="btn btn--primary"
//         data-action="click->modal#close">
//         Close
//       </button>
//     </div>
//   </div>
// </div>
//
// Style guide: components.modal

.modal__container,
[data-a11y-dialog-hide] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal__container {
  z-index: @z[modal-backdrop];
  display: flex;
}
.modal__container[aria-hidden="true"] {
  display: none;
}
[data-a11y-dialog-hide] {
  background-color: hsla(0, 0%, 0%, 0.5);
}
.modal__content {
  margin: auto;
  z-index: (@z[modal-backdrop] + 1);
  position: relative;
  background-color: white;
}







@modal-inner-padding-x: 1.5rem;

html[data-modal-active="true"] {
  // https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
  // On iOS, the CSS-based solution only works for version 13+, though.
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}


.modal {
  position: fixed;
  z-index: @z[modal-backdrop];
  display: none;

  // If js fails and data-modal-open can't be toggled, the modal
  // can still be opened and closed using the :target selector
  &[data-modal-open="true"],
  &:target {
    display: block;
  }
}
.modal__backdrop {
  position: fixed;
  z-index: (@z[modal-backdrop] + 1);
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  // Using calc here just to restrict vh to
  // devices where it doesn't cause issues
  height: calc(100vh);
  background-color: hsla(0, 0%, 0%, 0.5);
  cursor: auto;

  opacity: 0;
  transition: opacity @t[basic] ease;
}
.modal:target > .modal__backdrop,
// .has-js [data-modal-open="true"] > .modal__backdrop {
.has-js .is-open .modal__backdrop {
  opacity: 1;
}

.modal__wrapper {
  z-index: (@z[modal-backdrop] + 2);
  margin: auto;
  transition: transform @t[basic] ease;

  .has-js & {
    transform: scale(0.95);
  }
}
.has-js .is-open .modal__wrapper {
  transform: scale(1);
}
.modal__content {
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 40em;
  max-height: 100%;
  max-height: calc(90vh);
  // margin: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 0.25em;
  border-width: 0;
  // background-color: white;
  box-shadow: @box-shadow[basic];
}
.modal__header {
  position: relative;
  flex: 0 0 auto;
  padding: 1rem @modal-inner-padding-x 0;
  border-bottom: 1px solid @line-grey;
}
.modal__header-close {
  position: absolute;
  z-index: 2;
  display: block;
  top: 1.25em;
  right: @modal-inner-padding-x;
  padding-left: 0.7em;
  padding-right: 0.7em;
}
.modal__heading {
  padding-right: 2.6rem;
}
.modal__body {
  flex: 0 1 auto;
  overflow-y: auto;
}
.modal__body-inner {
  padding: 1.5em @modal-inner-padding-x 0.5em;
  min-width: 1px;
}
.modal__response {
  display: block;
  flex: 0 0 auto;
  padding: 0.5em @modal-inner-padding-x;
  border-top: 1px solid @line-grey;
}

