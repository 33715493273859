.pagination {
  margin: 2em 0;
  text-align: center;
}

.pagination__link-prev {
  margin-right: 0.5em;

  svg {
    margin-right: 0.15em;
  }
}
.pagination__link-next {
  margin-left: 0.5em;

  svg {
    margin-left: 0.15em;
  }
}

.pagination__link {
  .btn();
  .btn-colours(white; @grey2; @grey2; @blue-dark);
  border: 1px solid @grey3;
  font-style: normal;
  margin: 0.15em 0;
  padding-left: 1em;
  padding-right: 1em;

  &[aria-current="true"] {
    .btn--primary();
    border-color: @blue-dark;
  }
}
