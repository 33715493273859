a,
.link {
  word-break: break-word;
  word-wrap: break-word;
  color: inherit;
  background-color: transparent;
  cursor: pointer;

  text-decoration: underline;
  text-decoration-color: hsla(204, 100%, 30%, 0.6);
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 2px;

  &:extend(.link-transitions);

  &:hover,
  &:focus,
  &:active {
    text-decoration-color: @blue-dark;
  }

  main & {
    color: @blue-dark;
  }
}

a:not(.btn):not(.pagination__link),
.link:not(.btn) {
  outline: 1px solid transparent;
  outline-offset: @focus[offset];
}
a:not(.btn):not(.pagination__link):focus,
.link:not(.btn):focus {
  // outline: @focus[width] solid @focus[colour];
  outline: @focus[width] solid currentColor;
}

// pro-tip: <button> will display inline-block no matter what
.link {
  display: inline;
  padding: 0;
  background-color: transparent;
  border-width: 0;
}

.link-icon-base() {
  // Set up pseudo-element bases for link decorator icons for external links, PDFs, etc.
  //
  // Using a mixin here instead of basic universal styling, because a few extra redundant lines
  // are way less of a pain than having to override these pseudo-elements for anything else
  //
  // How to ensure pseudo-elements don't break off onto their own line:
  // https://www.jayfreestone.com/writing/wrapping-and-inline-pseudo-elements/
  &::before,
  &::after {
    display: inline;
    content: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  &::before {
    padding-right: 1em;
    margin: 0 0.25em 0 0.1em;
  }
  &::after {
    padding-left: 1em;
    margin: 0 0.1em 0 0.25em;
  }
}


// --------------------
// Link decorator icons
//
// A link decorator icon can be placed either ::before or ::after a link that matches a particular selector.
// There's definitely no need to decorate every link, but if you are going to give a link an icon, try to be
// consistent with where the icon is placed, depending on the link's behaviour.
//
// An icon gets placed in the ::before pseudo-element when the decorated link doesn't necessarily lead off-site, such as:
// - Opening a modal dialog (e.g. definition modals to explain a term)
// - Launching something out-of-browser (e.g. a mailto: link pulling up an email client, or a tel: link opening a phone app)
// - Downloading a resource (e.g. PDFs)
//
// An icon gets placed in the ::after pseudo-element when the decorated link does lead off-site, such as:
// - Going to protectchildren.ca
// - Opening a new tab/window


// Opening external sites in a new tab as default behaviour is not a recommended practice:
// https://codersblock.com/blog/external-links-new-tabs-and-accessibility/#accessibility-implications
// There are some exceptions to this, like having a link to our privacy policy in a form open in a new tab
// so that a user won't lose any information they've entered into the form fields if they follow the link.
[target="_blank"] {
  .link-icon-base();

  &::after {
    content: '';
    font-size: 0.75em;
    // Prevent icon getting too big at larger parent em sizes while still allowing it to scale up:
    font-size: calc(0.7em + 0.2 * (1rem - 0.65em));
    padding-top: 0.25em;
    background-image: url('@{image-path}icons/icon-external.svg');
  }
}

[href*="://protectchildren.ca"],
[href*="://protegeonsnosenfants.ca"],
[href*="www.protectchildren.ca"],
[href*="www.protegeonsnosenfants.ca"] {
  .link-icon-base();

  &::after {
    content: '';
    font-size: 0.8em;
    // Prevent icon getting too big at larger parent em sizes while still allowing it to scale up:
    font-size: calc(0.8em + 0.2 * (1rem - 0.75em));
    padding-top: 0.1em;
    opacity: 0.75;
    background-image: url('@{image-path}icons/icon-c3p.svg');
  }
}

[href*="://projectarachnid.ca"],
[href*="://projetarachnid.ca"],
[href*="www.projectarachnid.ca"],
[href*="www.projetarachnid.ca"] {
  .link-icon-base();

  &::after {
    content: '';
    font-size: 1.1em;
    // Prevent icon getting too big at larger parent em sizes while still allowing it to scale up:
    font-size: calc(1.1em + 0.2 * (1rem - 0.75em));
    line-height: 1;
    opacity: 0.8;
    background-image: url('@{image-path}icons/icon-arachnid.svg');
  }
}

[href$=".pdf"] {
  .link-icon-base();

  &::before {
    content: '';
    background-image: url('@{image-path}icons/icon-download.svg');
  }
  &::after {
    content: ' (PDF)';
    padding: 0;
    margin: 0;
    background-image: none;
  }
}
[href^="tel:"] {
  .link-icon-base();

  &::before {
    content: '';
    font-size: 0.85em;
    background-image: url('@{image-path}icons/icon-phone.svg');
  }
}
[href^="mailto:"] {
  .link-icon-base();

  &::before {
    content: '';
    font-size: 0.85em;
    background-image: url('@{image-path}icons/icon-mail.svg');
  }
}



@supports (mask-image: none) {
  .link-icon-mask-base() {
    content: none;
    background-image: none;
    mask-repeat: no-repeat;
    mask-position: center right;
    mask-size: contain;
    background-color: currentColor;
  }
  // The linear-gradient() function is included in the mask-image
  // declarations because of a Safari bug that lets the background-color
  // bleed on top and bottom: https://jsfiddle.net/34rmz5k7/2/
  [target="_blank"]:not(.card__link):not(.u-no-pseudo)::after {
    .link-icon-mask-base();
    content: '';
    mask-image:
      url('@{image-path}icons/icon-external.svg'),
      linear-gradient(transparent, transparent);
  }
  [href*="://protectchildren.ca"]:not(.u-no-pseudo)::after,
  [href*="://protegeonsnosenfants.ca"]:not(.u-no-pseudo)::after,
  [href*="://www.protectchildren.ca"]:not(.u-no-pseudo)::after,
  [href*="://www.protegeonsnosenfants.ca"]:not(.u-no-pseudo)::after {
    .link-icon-mask-base();
    content: '';
    opacity: 0.85;
    mask-image:
      url('@{image-path}icons/icon-c3p.svg'),
      linear-gradient(transparent, transparent);
  }
  [href*="://projectarachnid.ca"]:not(.u-no-pseudo)::after,
  [href*="://projetarachnid.ca"]:not(.u-no-pseudo)::after,
  [href*="www.projectarachnid.ca"]:not(.u-no-pseudo)::after,
  [href*="www.projetarachnid.ca"]:not(.u-no-pseudo)::after {
    .link-icon-mask-base();
    content: '';
    opacity: 0.85;
    mask-image:
      url('@{image-path}icons/icon-arachnid.svg'),
      linear-gradient(transparent, transparent);
  }
  [href$=".pdf"] {
    &:not(.card__link):not(.u-no-pseudo)::before {
      .link-icon-mask-base();
      content: '';
      mask-image:
        url('@{image-path}icons/icon-download.svg'),
        linear-gradient(transparent, transparent);
    }
  }
  [href^="tel:"]:not(.u-no-pseudo)::before {
    .link-icon-mask-base();
    content: '';
    mask-image:
      url('@{image-path}icons/icon-phone.svg'),
      linear-gradient(transparent, transparent);
  }
  [href^="mailto:"]:not(.u-no-pseudo)::before {
    .link-icon-mask-base();
    content: '';
    mask-image:
      url('@{image-path}icons/icon-mail.svg'),
      linear-gradient(transparent, transparent);
  }
}
