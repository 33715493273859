html {
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
}
.fade {
    width: 50%;
    height: 100vh;
    margin: auto;
    transition: opacity 0.3s ease-in;
    position: relative;
    scroll-snap-align: start;
    .fade-item {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.fade-in {
    opacity: 1;
}
.fade-out {
    opacity: 0;
}

@media screen and (max-width: 500px) {
    html {
        scroll-snap-type: none;
    }
    .fade {
        width: 90%;
        height: auto;
        min-height: 100vh;
    }
    .fade-out {
        opacity: 1;
    }
}
