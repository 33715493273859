@media print {
  
  // Good to err on the side of caution and greyscalify colour variables here.
  
  // CSS Grid and Flexbox declarations tend to really mess up print formatting,
  // so it's best to override those with block/inline-block/inline display.
  
  @page {
    margin: 1.75cm;
  }
  
  html {
    font-size: 85%;
  }
  body {
    display: block;
    overflow: visible;
  }
  
  img {
    max-width: 400px !important;
    filter: grayscale(1);
  }
  
  a {
    color: black !important;
    font-weight: bold !important;
    text-decoration: underline;
    text-decoration-color: #bbb;
    box-shadow: none !important;
    background-image: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  // Display links:
  a::after {
    position: relative !important;
    width: auto;
    // content: " (https://sitename.ca" attr(href) ") ";
  }
  [lang="fr"] a::after {
    width: auto;
    // content: " (https://sitename-en-français.ca" attr(href) ") ";
  }
  a[href^="http://"]::after,
  a[href^="https://"]::after,
  a[target="_blank"]::after {
    display: inline !important;
    padding: 0 !important;
    height: auto !important;
    background-color: transparent !important;
    width: auto !important;
    content: " (" attr(href) ")" !important;
    background-image: none !important;
    color: #000;
    mask-image: none !important;
  }
  // But not these:
  a[href^="#"]::after,
  a[href^="tel:"]::after,
  a[href^="mailto:"]::after,
  a[href^="javascript:"]::after {
    content: none !important;
  }
  
  abbr {
    text-decoration: none !important;
    border-bottom: none !important;
  }
  
  .site-header__container, .site-header {
    border: 0;
    box-shadow: none;
  }

  nav.header-menu,
  .nav-menu,
  #header-menu,
  .section-nav__container,
  .nav-menu__toggle-wrapper,
  .fancy-heading__img,
  .fancy-heading__logo--alerts,
  #section-navigation,
  .btn,
  .video,
  .card__img,
  #ctip-alerts-signup,
  .form-group,
  footer.site-footer {
    display: none;
  }
  .fancy-heading {
    padding: 0;
    margin: 0;
  }

  .fancy-heading__text {
    color: #000;
    text-shadow: none;
    margin-top: 30px;
  }

  .site-header__logo-wrapper {
    text-align: center;
  }

  .site-header__tagline {
    margin: auto;
  }

  .l-main-content {
    padding-top: 0;
  }

  .collapsible__icon {
    display: none !important;
  }

  .collapsible__panel {
    height: auto;
    max-height: 100% !important;
    &.collapser {
      visibility: visible !important;
    }
  }
  
  .callout--warning {
    background-color: #fff;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    color: #000;
    *:not(.btn) {
      color: #000;
    }
  }

  .card {
    background-color: transparent;
  }

  blockquote {
    &::before,
    &::after {
      color: #000;
    }
  }
  
}
