.img-banner {
  position: relative;
  z-index: 0;
}
.img-banner__img-wrapper {
  position: relative;
  z-index: 1;
  padding: 2.5em 0 1em;
}
.img-banner__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.img-banner__text {
  padding: 1.5em 0;
}

.img-banner--primary {
  color: white;
  background-color: darken(@blue, 3%);

  p,
  a {
    color: inherit;
  }
  a {
    text-decoration-color: @blue-light;

    &:hover,
    &:active,
    &:focus {
      text-decoration-color: white;
    }
  }
}
.img-banner__heading--primary {
  width: 60%;
  max-width: 7em;
  text-shadow: 0 0 4px darken(@blue, 3%);

  @media screen and (min-width: 30em) {
    padding-top: 0.5em;
    width: 50%;
  }
}
.img-banner__img--primary {
  background-image: url('@{image-path}content/front-page/primary.jpg');
  background-size: cover;
//  background-size: auto 100%;
//  background-position: right -6em center;

  // strictly background-position adjustments
  @media screen and (min-width: 24em) {
    background-position: center;
  }
  @media screen and (min-width: 36em) {
    background-position: 58% center;
  }
}
.img-banner__text--primary {
  text-shadow:
    -1px -1px 2px @blue-dark,
    -1px 1px 2px @blue-dark,
    1px -1px 2px @blue-dark,
    1px 1px 2px @blue-dark
    ;
}

.image-text--primary {
  &::before {
    position: absolute;
    content: 'Models in image. Intended as illustrative.';
    right: 0.5em;
    bottom: 0.5em;
    font-size: 0.5rem;
    font-family: @fonts[body];
    font-weight: normal;
    color: white;
    opacity: 0.5;
    text-shadow:
      0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
      0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
  }
  [lang="fr"] &::before {
    content: 'Photos de mannequins. Pour illustrer le texte.';
  }
}

.img-banner__img-wrapper--reporting {
  height: 14em;
}
.img-banner__img--reporting {
  background-image: url('@{image-path}content/front-page/HomepageBanner_2200x625.jpg');
  background-color: white;
//  background-image:
//    url('@{image-path}content/front-page/reporting.jpg'),
//    linear-gradient(to right, #333736, #333736 50%, white 50%, white);
//    background-size: cover;
  background-size: cover;
//  background-position: 30% center;
}

.img-banner--arachnid {
  background-color: @grey-darker;
  color: white;
}
.img-banner__img-wrapper--arachnid {
  height: 14em;
}
.img-banner__img--arachnid {
  background-image: url('@{image-path}content/front-page/arachnid.jpg');
  background-color: @grey-darker;
  background-size: auto 100%;

      @media screen and (min-width: 24em) {
        background-position: 100% 20%;
      }
//  background-image:
//    url('@{image-path}content/front-page/gettyimages-1057104526-1024x1024.jpg');
//    linear-gradient(to right, @grey-darker, @grey-darker 60%, black 60%, black);
//    background-size: cover;
//  background-size: auto 130%;
//  background-position: 70% 20%;
}

@media screen and (min-width: 36em) {
  .img-banner__img-wrapper {
    position: static;
    padding-bottom: 0;
    height: auto;
  }
  .img-banner__text {
    padding-top: 0;
  }

  .img-banner--primary {
    padding: 1em 0;
  }
  .img-banner__heading--primary {
    padding-top: 0;
    width: 65%;
    font-size: 3.2em;
  }
  .img-banner__text--primary {
    width: 65%;
  }

  .img-banner__text--twenty {
    width: 65%;
  }

  .img-banner--twenty {
    padding: 1em 0;
  }
  .img-banner__heading--twenty {
    padding-top: 0;
    width: 65%;
    font-size: 3.2em;
  }
  .img-banner__text--twenty {
    width: 50%;
  }

  .img-banner__img--reporting {
    background-position: 30% center;
  }

  .img-banner__text--reporting {
    float: right;
    width: 75%;
    text-align: right;
  }

  .img-banner__text--arachnid {
    width: 60%;
  }
}
@media screen and (min-width: 46em) {
  .img-banner__heading--primary,
  .img-banner__text--primary {
    max-width: none;
    width: 55%;
  }

  .img-banner__text--reporting,
  .img-banner__text--arachnid {
    padding: 3em 0;
  }

}
@media screen and (min-width: 60em) {
  .img-banner__text--reporting {
    padding: 1em 0;
  }

  .img-banner__heading--reporting {
    float: left;
  }

  .img-banner__img--arachnid {
    background-position: 30% center;
  }
}


.img-banner--donate {
  background-color: #fcfce0;
}

.img-banner__img-wrapper--donate {
  height: 16em;
}

.img-banner__img--donate {
  background-image: url('@{image-path}content/donation-page/donate.jpg');
  background-size: cover;
  height: 16em;

  // strictly background-position adjustments
  @media screen and (min-width: 24em) {
    background-position: center;
  }
  @media screen and (min-width: 36em) {
    background-position: 58% center;
  }
}

.img-banner__img--twenty {
  background-image: url('@{image-path}content/front-page/ctip20_homepage_banner_en.jpg');
  background-size: cover;

  // strictly background-position adjustments
  @media screen and (min-width: 24em) {
    background-position: center;
  }
  @media screen and (min-width: 36em) {
    background-position: 60% center;
  }
}

[lang="fr"] .img-banner__img--twenty {
  background-image: url('@{image-path}content/front-page/ctip20_homepage_banner_fr.jpg');
}

.image-text--twenty {
  &::before {
    position: absolute;
    content: 'Models in image. Intended as illustrative.';
    right: 0.5em;
    bottom: 0.5em;
    font-size: 0.5rem;
    font-family: @fonts[body];
    font-weight: normal;
    color: white;
    opacity: 0.5;
    text-shadow:
      0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
      0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
  }
  [lang="fr"] &::before {
    content: 'Photos de mannequins. Pour illustrer le texte.';
  }
}

.img-banner__heading--twenty {
  width: 70%;
  max-width: 10em;

  @media screen and (min-width: 30em) {
    padding-top: 0.5em;
    width: 70%;
  }
}

.img-banner--twenty {
  color: white;
  background-color: darken(@blue, 3%);

  a {
    color: @blue;
  }
  a {
    text-decoration-color: @blue-light;

    &:hover,
    &:active,
    &:focus {
      text-decoration-color: white;
    }
  }
}

.fancy-heading__logo--twenty-home {
  position: absolute;
  width: 20em;
  width: calc(20em + 4vw);
  max-width: 40em;
  top: 16em;
  right: 8em;
}
