// Callout
//
// Markup:
// <div class="callout {{modifier_class}} u-mt-xs u-mb-md">
//   <h3 class="u-colour-inherit u-mb-sm">An example callout</h3>
//   <p>
//     A callout is usually a <code>div</code> or a <code>p</code>, though the styling can be applied to just about any block-level
//     element. Here’s a <a href="#" target="_blank">fake link</a>.
//   </p>
// </div>
//
// .callout--dark       - Well, it’s a dark callout, I don’t know what else to tell you.
// .callout--success    - For a successful/positive message.
// .callout--warning    - For an important notice/warning message.
// .callout--blue       - It’s blue.
// .callout--blue-dark  - It’s blue in dark mode.
//
// Style guide: components.callout

.callout {
  .callout-base();

  display: block;
  background-color: @grey2;
  border-color: @grey3;
}

.callout--success {
  background-color: lighten(@green-light, 30%);
  border-color: @green;
  color: @green-dark;

  *:not(.btn) {
    color: @green-dark;
  }
  a:not(.btn),
  .link {
    color: @green-dark;
    text-decoration-color: hsla(105, 48%, 25%, 0.6);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: @green-dark;
    }
  }
}

.callout--warning {
  background-color: lighten(@red-light, 37%);
  border-color: @red;
  color: @red-dark;

  *:not(.btn) {
    color: @red-dark;
  }
  a:not(.btn),
  .link {
    color: @red-dark;
    text-decoration-color: hsla(352, 83%, 35%, 0.6);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: @red;
    }
  }
}

.callout--info {
  .callout--blue();
  position: relative;
  padding-left: 2.7em;

  &::before {
    position: absolute;
    content: '';
    left: 0.65em;
    width: 1.5em;
    height: 1.5em;
    background-color: currentColor;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-image: url('@{image-path}icons/icon-info.svg');
  }
}

.callout--blue {
  background-color: lighten(@blue-light, 30%);
  border-color: @blue;
  color: @blue-dark;

  *:not(.btn) {
    color: @blue-dark;
  }
}

.callout--blue-dark {
  background-color: darken(@blue-dark, 5%);
  border-color: @blue;
  color: white;

  *:not(.btn) {
    color: white;
  }
  a:not(.btn),
  .link {
    text-decoration-color: hsla(0, 0%, 100%, 0.6);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: white;
    }
  }
}

.callout--dark {
  background-color: @grey-dark;
  border-color: darken(@grey-darker, 5%);
  color: white;

  *:not(.btn):not(span) {
    color: white;
  }
  a:not(.btn),
  .link {
    text-decoration-color: hsla(0, 0%, 100%, 0.6);

    &:hover,
    &:focus,
    &:active {
      text-decoration-color: white;
    }
  }
}
