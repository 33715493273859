// Feature list
//
// Style guide: components.feature-list

.feature-list {
  list-style: none;
  margin-left: 0;
}
.feature-list__item {
  margin-bottom: @global-margin-bottom;
  border: solid @blue;
  border-width: 0 0 0 0.2em;
}
.feature-list__heading,
.feature-list__text {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin-bottom: 0;
  padding: 0.5rem 0.5rem 1rem 1.25rem;
}
.feature-list__heading {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 2.25em;
  color: @blue-dark;
  background-color: @grey2;
  text-transform: uppercase;

  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 0.8rem solid transparent;
    border-left-color: @blue;
  }

}
