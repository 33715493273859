html {
  font-size: 100%; // 16px base fallback
  
  // These days (ie9+) we can have real actual responsive type! NICE
  // ----------------------------------------------------------------------
  // Here's how most explanations outline the method:
  //
  //       1. min font-size       3. min screen size
  //               v                     v
  font-size: calc(1em + 1.1 * ((100vw - 500px) / (1000 - 500) ) );
  //                     ^                             ^
  //  2. (max font-size) minus (min font-size)    4. (max screen size) minus (min screen size)
  // ----------------------------------------------------------------------
  //
  // Where sizes are declared as ems or rems, they will scale in response 
  // to the browser's viewport width according to the above function.
  //
  // 1. The root font-size will stay between min & max font-sizes.
  // 2. The difference of the maximum and minimum intended font-sizes
  //    determines how dramatically everything scales.
  // 3. This is the minimum screen size at which font-size begins to scale.
  // 4. The difference of the maximum and minimum screen sizes determines
  //    the viewport width at which everything can stop scaling up.
  
  line-height: @line-height[body];
  height: 100%;
  box-sizing: border-box;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: @fonts[body];
  font-style: normal;
  font-weight: normal;
  // color: inherit;
  color: @text-colour;
}

section {
  position: relative;
}

input,
textarea,
select,
button {
  font-family: inherit;
}
// yanked from bootstrap 4's reboot.css
a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

button {
  font-family: inherit;
  word-break: break-word;
  word-wrap: break-word;
  cursor: pointer;
}
button > span {
  // because webkit bug: https://bugs.webkit.org/show_bug.cgi?id=39620
  pointer-events: none;
}

b, strong {
  font-weight: bold;
}
i, em, cite {
  font-style: italic;
}
// <q> truly is a magical element for inline quotes.
// Adding thin non-breaking spaces here inside the guillemets
// to conform to our Canadian French quoting style:
[lang="fr"] q {
  &::before {
    content: '«\202F';
  }
  &::after {
    content: '\202F»';
  }
}
ol, ul {
  padding-left: 0;
  margin-left: 1.2em;
  margin-left: calc(1.2em + 2 * ((100vw - 300px) / 200));
  
  ol, ul {
    margin-bottom: 0;
  }
}
dt {
  font-weight: bold;
}
sup {
  font-size: 0.7em;
  // text-transform: lowercase;
}
abbr {
  text-decoration: none;
  border-bottom: none;
}
abbr[title],
span[title] {
  cursor: help;
  text-decoration: underline dotted hsla(0, 0%, 50%, 0.5);
}
// uses zero-width space to make IE respect <wbr> behaviour
wbr::after {
  content: '\00200B';
}
address {
  font-style: normal;
}
hr {
  margin: 2rem 0;
  height: 0;
  border: solid @line-grey;
  border-width: 0.125rem 0 0;
  
  &.rule--light {
    border-color: @grey1;
  }
  &.rule--dark {
    border-color: @grey-dark;
  }
}
video,
audio {
  display: block;
  max-width: 100%;
}
video {
  width: 100%;
}
pre,
code {
  font-family: @fonts[mono];
  line-height: @line-height[mono];
  font-size: 0.9em;
}
pre {
  white-space: pre;
  overflow: scroll;
}
code {
  margin-left: 0.1em;
  margin-right: 0.1em;
  padding: 0.15em 0.3em;
  color: inherit;
  background-color: hsla(11, 3%, 6%, 0.07);
  border-radius: 0.2em;
  box-shadow: inset 0 0 0.2em hsla(0, 0%, 0%, 0.15);
}
mark {
  padding: 0 0.2em;
  background-color: @blue-dark;
  color: white;
}

// base rhythm
// https://csswizardry.com/2012/06/single-direction-margin-declarations/
h1, h2, h3, h4, h5, h6,
ul, ol, dl, dd, p, figure,
pre, table, fieldset, address, blockquote {
  margin-top: 0;
  margin-bottom: 1.25rem;
}
