.captcha {
  .callout();
  border-width: 0;
}
.captcha__container {
  margin-bottom: 1rem;
}
.captcha__image {
  display: block;
  margin-bottom: 0.5rem;
  border: 1px solid @grey5;
}
