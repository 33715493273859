// Alert
//
// Borrows a lot of styling from the callout component. Alerts, however, are better used for bite-sized response messages.
//
// .alert--info     - For an informational message.
// .alert--success  - For a successful/positive message.
// .alert--warning  - For a notice/warning message.
// .alert--error    - For an error/failure message.
//
// Style guide: components.alert

.alert {
  .callout-base(@padding: 1rem; @border-width: 2px);

  display: block;
  background-color: @grey2;
  border-color: @grey3;
  border-radius: 0.35em;
}

.alert--success {
  .callout--success();
}
.alert--warning,
.alert--error {
  .callout--warning();
}
.alert--info {
  .callout--info();
}
