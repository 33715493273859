// Figures
//
// Markup:
// <figure>
//   <img src="http://placekitten.com/440/300" width="440" height="300" alt="Placeholder kitten(s)">
//   <figcaption>
//     This is a caption for an image served by <a href="http://placekitten.com">http://placekitten.com</a>
//   </figcaption>
// </figure>
//
// Style guide: base.figures

figure {
  display: table;
  margin-left: 0;
  margin-right: 0;
}
figcaption {
  display: table-caption;
  caption-side: bottom;
  font-size: 0.85em;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid @line-grey;
}



@media screen and (min-width: 30em) {
  .figure--sm-left {
    float: left;
    margin: 1em 1.5em 1em 0;
  }
  .figure--sm-right {
    float: right;
    margin: 1em 0 1em 1.5em;
  }
}
@media screen and (min-width: 42em) {
  .figure--md-left {
    float: left;
    margin: 1em 1.5em 1em 0;
  }
  .figure--md-right {
    float: right;
    margin: 1em 0 1em 1.5em;
  }
}
