.reports-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    td {
        width: 50%;
    }
    thead {
        border: thin solid @blue-dark;
        td {
            background-color: @blue-dark;
            color: #fff;
            padding: 10px 20px;
            font-weight: bold;
            text-align: center;
        }
    }
    tbody {
        border: thin solid @grey3;
        font-size: 0.9em;
        tr:nth-child(even) {
            background-color: @grey2;
        }
        td {
            padding: 10px 20px;
            text-align: right;
            &:first-child {
                border-right: thin solid @grey3;
            }
        }
    }
}
