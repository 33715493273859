// For any gross fixes, last-minute hacks, etc.
// http://csswizardry.com/2013/04/shame-css/

// shame on YOU for putting divs where they don't belong, opera
html > div {
  display: none !important;
}

// .callout--dev
//
// TEMPORARY
.callout--dev {
  --borderrad: 0.5rem;
  --construction-yellow: hsl(50, 90%, 60%);

  position: relative;
  padding-top: 1.65rem;
  padding-bottom: 1.7rem;
  overflow-x: scroll;
  background-color: var(--construction-yellow);
  border-width: 0;
  border-radius: var(--borderrad);
  color: inherit;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    font-size: 0.7rem;
  }
  &::before {
    top: 0;
    content: 'DEV NOTE';
    color: var(--construction-yellow);
    font-weight: bold;
    padding-left: 2rem;
    background-image:
      linear-gradient(135deg, transparent, transparent 2em, black 2em, black 6em, transparent 6em),
      repeating-linear-gradient(135deg, black, black 1em, var(--construction-yellow) 1em, var(--construction-yellow) 2em);
    border-top-left-radius: var(--borderrad);
    border-top-right-radius: var(--borderrad);
    text-shadow:
       1px  1px 0 black,
      -1px  1px 0 black,
      -1px -1px 0 black,
       1px -1px 0 black;
  }
  &::after {
    bottom: 0;
    height: 0.75rem;
    background-image: repeating-linear-gradient(135deg, black, black 1em, var(--construction-yellow) 1em, var(--construction-yellow) 2em);
    border-bottom-left-radius: var(--borderrad);
    border-bottom-right-radius: var(--borderrad);
  }

}

// .flag-placeholder-links
//
// Highlight placeholder/go-nowhere links to make 'em noticeable.
//
// TEMPORARY
.flag-placeholder-links a:not([href]),
.flag-placeholder-links [href=""],
.flag-placeholder-links [href="#"] {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    z-index: -1;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(
      45deg,
      hsla(0,100%,50%,0.2),
      hsla(0,100%,50%,0.2) 0.2em,
      hsla(0,100%,50%,0.1) 0.2em,
      hsla(0,100%,50%,0.1) 0.4em
    );
  }
}
