// Collapsible
//
// The collapsible component uses the [collapser](/styleguide/section-global/#kssref-global-collapser) styling and script.
//
// ### Using the collapsible macro
// The macro `m.collapsible()` accepts the following arguments:
// ```jinja2
// [% raw %][% call m.collapsible(heading, heading_level, starts_open) %]
//   Collapsible content
// [% endcall %][% endraw %]
// ```
// * `heading`: **Required.**
// * `heading_level`: Optional. Defaults to `h2`. Modify as needed to ensure the collapsible’s
//   [heading is ranked properly](https://www.w3.org/WAI/tutorials/page-structure/headings/).
// * `starts_open`: Optional. Defaults to `false`.
//
// ### Example usage
// Using the macro as follows will give you the collapsibles in the example shown below.
//
// ```html
// [% raw %]
// <div class="collapsibles">
//
//   [% call m.collapsible(
//     heading = 'Heading text',
//     heading_level = 'h3'
//   ) %]
//     <p>Facit igitur Lucius noster prudenter, qui audire de summo bono potissimum velit.</p>
//     <ul>
//       <li>Multoque hoc melius nos veriusque quam Stoici.</li>
//       <li>Quod autem principium officii quaerunt, melius quam Pyrrho.</li>
//     </ul>
//     <p>Aliter enim nosmet ipsos nosse non possumus. <b>Occultum facinus esse potuerit, gaudebit.</b></p>
//   [% endcall %]
//
//   [% call m.collapsible(
//     heading = 'Another bit of heading text that is somewhat longer',
//     heading_level = 'h3',
//     starts_open = true
//   ) %]
//     <p>The collapsible’s body text.</p>
//   [% endcall %]
//
// </div>
// [% endraw %]
// ```
//
// Markup:
// <div class="collapsibles">
//   <div class="collapsible" data-controller="collapsible">
//     <h3 class="collapsible__heading" data-collapsible-target="heading">
//       <button class="collapsible__toggle"
//         data-collapsible-target="toggleButton"
//         data-action="click->collapsible#toggle">
//         Heading text
//         <svg class="collapsible__icon" viewBox="0 0 100 100" width="30" height="30" role="img" aria-hidden="true">
//           <path d="M50 79L9 38l9-9 32 32 32-32 9 9z"/>
//         </svg>
//       </button>
//     </h3>
//     <div class="collapsible__panel collapser"
//       data-collapsible-target="panel">
//       <div class="collapsible__panel-inner">
//         <p>Facit igitur Lucius noster prudenter, qui audire de summo bono potissimum velit.</p>
//         <ul>
//           <li>Multoque hoc melius nos veriusque quam Stoici.</li>
//           <li>Quod autem principium officii quaerunt, melius quam Pyrrho.</li>
//         </ul>
//         <p>Aliter enim nosmet ipsos nosse non possumus. <b>Occultum facinus esse potuerit, gaudebit.</b></p>
//       </div>
//     </div>
//   </div>
//   <div class="collapsible" data-controller="collapsible">
//     <h3 class="collapsible__heading" data-collapsible-target="heading">
//       <button class="collapsible__toggle starts-open"
//         data-collapsible-target="toggleButton"
//         data-action="click->collapsible#toggle">
//         Another bit of heading text that is somewhat longer
//         <svg class="collapsible__icon" viewBox="0 0 100 100" width="30" height="30" role="img" aria-hidden="true">
//           <path d="M50 79L9 38l9-9 32 32 32-32 9 9z"/>
//         </svg>
//       </button>
//     </h3>
//     <div class="collapsible__panel collapser starts-open"
//       data-collapsible-target="panel">
//       <div class="collapsible__panel-inner">
//         <p>The collapsible’s body text.</p>
//       </div>
//     </div>
//   </div>
// </div>
//
// Style guide: components.collapsible

.collapsibles {
  margin: 2em 0;
}
.collapsibles__inner-container {
  text-align: right;
  margin-bottom: 2em;
}
.collapsible {
  display: block;
  margin-left: 0;
  border: solid @line-grey;
  border-width: 1px 0;
  .clearfix();

  & + & {
    border-width: 0 0 1px;
  }
}
.collapsibles--red,
.collapsible--red {
  .collapsible__toggle:hover,
  .collapsible__toggle:focus,
  .collapsible__toggle:active,
  .collapsible__toggle[aria-expanded="true"] {
    color: @red;
  }
}

.collapsible__heading {
  font-size: 1.75em;
  margin: 0;
}
.collapsible__toggle {
  all: inherit;
  position: relative;
  display: block;
  width: 100%;
  padding: 0.85rem 2em 1.25rem 1rem;
  font-weight: normal;
  text-align: left;
  background-color: transparent;
  border-width: 0;
  outline: 1px solid transparent;
  transition: color @t[basic], outline @t[basic];

  &:hover,
  &:focus,
  &:active,
  &[aria-expanded="true"] {
    color: @blue;
  }
  &:focus {
    outline: @focus[width] solid currentColor;
  }

  .has-js & {
    cursor: pointer;
  }
}

.collapsible__icon {
  position: absolute;
  display: none;
  top: 0.75em;
  right: 0.5em;
  width: 0.9em;
  height: 0.9em;
  transform: rotate(0);
  transition: transform @t[slow];

  .has-js & {
    display: block;
  }
  [aria-expanded="true"] > & {
    transform: rotate(-180deg);
  }
}
.collapsible__panel {
  // The collapsible component located in js/Collapsible.vue handles
  // all the essential collapsing/expanding and visibility behaviour.
  // See its <style> section for all declarations related to that behaviour.

  transition: max-height @t[slow] ease-in-out;
}
.collapsible__panel-inner {
  padding: 0.75em 1rem 1em;
}
