// All other card declarations in
// components/card.less

// Make class names as general as possible when an image could be reused,
// rather than specific to a press release title/subject

.card__img {
  // default:
  background-image: url('@{image-path}content/news-releases/c3p-generic.jpg');

  &.phoenix11-statement-en { background-image: url('@{image-path}content/news-releases/safer-internet-day-2019.jpg'); }
  &.phoenix11-statement-fr { background-image: url('@{image-path}content/news-releases/safer-internet-day-2019.jpg'); }
  &.yournudeisoutthere-en { background-image: url('@{image-path}content/news-releases/safer-internet-day-2019.jpg'); }
  &.yournudeisoutthere-fr { background-image: url('@{image-path}content/news-releases/safer-internet-day-2019.jpg'); }
  &.arachnid-international { background-image: url('@{image-path}content/news-releases/safer-internet-day-2019.jpg'); }
  &.safer-internet-day-2019 { background-image: url('@{image-path}content/news-releases/safer-internet-day-2019.jpg'); }
  &.arachnid-croatia { background-image: url('@{image-path}content/news-releases/arachnid-croatia.jpg'); }
  &.arachnid-australia { background-image: url('@{image-path}content/news-releases/arachnid-australia.jpg'); }
  &.lollipoptakedown-en { background-image: url('@{image-path}content/news-releases/lollipoptakedown-en.jpg'); }
  &.lollipoptakedown-fr { background-image: url('@{image-path}content/news-releases/lollipoptakedown-fr.jpg'); }
  &.safer-internet-day-2020 { background-image: url('@{image-path}content/news-releases/safer-internet-day-2020.jpg'); }
  &.csam-survey { background-image: url('@{image-path}content/news-releases/csam-survey.jpg'); }
  &.ethics-committee { background-image: url('@{image-path}content/news-releases/ethics-committee.jpg'); }
  &.safer-internet-day-2021 { background-image: url('@{image-path}content/news-releases/safer-internet-day-2021.jpg'); }
  &.mindgeek-statement { background-image: url('@{image-path}content/news-releases/mindgeek-statement.jpg'); }
  &.ph11-statement { background-image: url('@{image-path}content/news-releases/ph11-statement.jpg'); }
  &.transparency-report-en { background-image: url('@{image-path}content/news-releases/transparency-report-en.jpg'); }
  &.transparency-report-fr { background-image: url('@{image-path}content/news-releases/transparency-report-fr.jpg'); }
  &.stats-canada { background-image: url('@{image-path}content/news-releases/stats-canada.jpg'); }
  &.apple-statement { background-image: url('@{image-path}content/news-releases/apple-statement.jpg'); }
  &.google-statement { background-image: url('@{image-path}content/news-releases/google-statement.jpg'); }
  &.osv-increase { background-image: url('@{image-path}content/news-releases/safer-internet-day-2022.jpg'); }
  &.sextortion-data-august2022 { background-image: url('@{image-path}content/news-releases/sextortion_stats_presser.jpg'); }
  &.ctip-20-en { background-image: url('@{image-path}content/news-releases/CTIP20_en.jpg'); }
  &.ctip-20-fr { background-image: url('@{image-path}content/news-releases/CTIP20_fr.jpg'); }
  &.microphone { background-image: url('@{image-path}content/news-releases/microphone.jpg'); }
  &.roundtable-statement { background-image: url('@{image-path}content/news-releases/roundtable-statement.jpg'); }
  &.SID2023 { background-image: url('@{image-path}content/news-releases/SID2023.jpg'); }
  &.SID2024 { background-image: url('@{image-path}content/news-releases/C3P_27033_SID2024.jpg'); }
  &.RCMP-sextortion-poster-en { background-image: url('@{image-path}content/news-releases/Ctip_27053_RCMPSextortionPosterOnSextortionPage_700x455_en.jpg'); }
  &.RCMP-sextortion-poster-fr { background-image: url('@{image-path}content/news-releases/Ctip_27053_RCMPSextortionPosterOnSextortionPage_700x455_fr.jpg'); }
}
  &.sid2025 { background-image: url('@{image-path}content/news-releases/SID_2025.jpg'); }
