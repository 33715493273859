.l-columns {
  column-width: 16em;
  column-gap: 1em;

  // Give more space for list styles
  ul&,
  ol& {
    column-gap: 2em;
  }

  > * {
    break-inside: avoid;
  }
}
.l-columns--xs {
  column-width: 8em;
}
.l-columns--sm {
  column-width: 11em;
}
.l-columns--lg {
  column-width: 24em;
}
