// Video player
//
// Want to set up a video with a nice player? Use the attribute `data-controller="video-player"` directly on the `<video>` element and it’ll
// get enhanced with the [plyr library](https://github.com/sampotts/plyr). All the rest of the video’s markup can be the same as if it were
// a plain HTML5 video element.
//
// ### Example usage
//
// ```html
// <video
//   data-controller="video-player"
//   controls
//   playsinline
//   webkit-playsinline
//   preload="none"
//   poster="[[ static('images/content/public-awareness/2021_ncdii-spring-en.jpg') ]]">
//   <source src="https://s3.amazonaws.com/cybertip.ca/2021_ncdii-spring-en.mp4" type="video/mp4" size="720">
//   <source src="https://s3.amazonaws.com/cybertip.ca/2021_ncdii-spring-en.webm" type="video/webm" size="720">
// </video>
// ```
//
// Markup:
// <video
//   data-controller="video-player"
//   controls playsinline webkit-playsinline preload="none"
//   poster="[[ static('images/content/public-awareness/2021_ncdii-spring-en.jpg') ]]">
//   <source src="https://s3.amazonaws.com/cybertip.ca/2021_ncdii-spring-en.mp4" type="video/mp4" size="720">
//   <source src="https://s3.amazonaws.com/cybertip.ca/2021_ncdii-spring-en.webm" type="video/webm" size="720">
// </video>
//
// Style guide: components.video

.video {
  max-width: 100%;
  margin-bottom: 1.25rem;
}
.video--bg {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}
