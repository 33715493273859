.l-main-content {
  display: block;
  flex: 1 0 auto;
  min-height: 17em;
  padding: 2em 0 3em;
}

.l-container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: @side-spacing;
  padding-right: @side-spacing;
  max-width: @container-width;
}

.l-reading-width {
  max-width: 40rem;

  .l-container & {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}



@supports (display: grid) {
  @media screen and (min-width: 60em) {
    .l-grid--2up-lg {
      display: grid;
      grid-gap: 1.5em;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
