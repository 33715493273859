.skip-to-content {
  position: absolute;
  display: block;
  z-index: @z[header] + 1;
  top: 0.5em;
  left: -9999px;
  padding: 0.2em 0.5em;
  font-weight: bold;
  transform: translateY(-110%);
  color: white;
  background-color: @blue-dark;
  text-decoration: none;
  transition: transform @t[snappy] ease-out;
  
  &:focus,
  &:active {
    left: 0.5em;
    transform: translateY(0);
    box-shadow: none;
  }
}
