// Collapser
//
// This class handles visibility and animation properties for `js/controllers/collapse_base.js` and is not a component in of itself.
//
// Used in:
// * The [collapsible component](/styleguide/section-components/#kssref-components-collapsible)
// * The [section nav](/styleguide/section-components/#kssref-components-section-nav)
//
// Style guide: global.collapser

@collapse-transition: @t[slow] ease-out;

.collapser {
  overflow: hidden;

  .has-js & {
    // Hides without affecting layout, making it possible to calculate max-height
    visibility: hidden;
    max-height: 0;
    transition: max-height @collapse-transition;
  }
  &.starts-open,
  &.is-visible {
    visibility: visible;
  }
  &.starts-open,
  .has-js &.starts-open {
    max-height: none;
  }
}
// If a collapser component is nested inside another one, when the nested collapser
// is open but the parent is not, set the nested collapser’s visibility to hidden.
// This will prevent any elements inside the nested collapser from receiving focus
// while they’re scoped within `aria-hidden="true"` and therefore, according to how
// the collapser script functions, visually hidden.
// https://dequeuniversity.com/rules/axe/3.2/aria-hidden-focus
.collapser[aria-hidden="true"] .collapser.is-visible {
  visibility: hidden;
}
