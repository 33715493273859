.font-mixin(@font-family; @font-weight; @font-style; @font-stretch; @font-src) {
  font-family: @font-family;
  font-weight: @font-weight;
  font-style: @font-style;
  font-stretch: @font-stretch;
  src: url('@{font-path}@{font-src}-subset.woff2') format('woff2'),
       url('@{font-path}@{font-src}-subset.zopfli.woff') format('woff');
  font-display: swap;
}
.font-mixin-nosubset(@font-family; @font-weight; @font-style; @font-stretch; @font-src) {
  font-family: @font-family;
  font-weight: @font-weight;
  font-style: @font-style;
  font-stretch: @font-stretch;
  src: url('@{font-path}@{font-src}.woff2') format('woff2'),
       url('@{font-path}@{font-src}.woff') format('woff');
  font-display: swap;
}


@font-face { .font-mixin('OpenSans'; normal; normal; normal; 'OpenSans-Regular'); }
@font-face { .font-mixin('OpenSans'; normal; italic; normal; 'OpenSans-Italic'); }
@font-face { .font-mixin('OpenSans'; bold;   normal; normal; 'OpenSans-Bold'); }
@font-face { .font-mixin('OpenSans'; bold;   italic; normal; 'OpenSans-BoldItalic'); }


@font-face { .font-mixin-nosubset('acumin-pro-extra-condensed'; normal; normal; normal; 'acumin-pro-extra-condensed-regular'); }
@font-face { .font-mixin-nosubset('acumin-pro-extra-condensed'; normal; italic; normal; 'acumin-pro-extra-condensed-italic'); }
@font-face { .font-mixin-nosubset('acumin-pro-extra-condensed'; bold;   normal; normal; 'acumin-pro-extra-condensed-bold'); }
@font-face { .font-mixin-nosubset('acumin-pro-extra-condensed'; bold;   italic; normal; 'acumin-pro-extra-condensed-bolditalic'); }


// Include Oswald as backup
// @font-face { .font-mixin('Oswald'; normal; normal; normal; 'Oswald-Light'); }
// @font-face { .font-mixin('Oswald'; bold;   normal; normal; 'Oswald-Regular'); }
