.partners-banner {
  padding: 3em 0;
  text-align: center;
  background-color: @grey1;
}
.partners-banner__logo {
  margin: 1.25em 1.75em;
}



@supports (display: grid) {
  @media screen and (min-width: 46em) {
    .partners-banner {
      text-align: left;
    }
    .partners-banner__grid {
      display: grid;
      grid-template-columns: minmax(14em, 3fr) 8fr;
      grid-template-rows: auto auto 1fr;
      grid-template-areas:
        "heading logos"
        "text    logos"
        "link    logos";
    }
    .partners-banner__heading {
      grid-area: heading;
    }
    .partners-banner__logos {
      grid-area: logos;
      padding-left: 1em;
      align-self: center;
    }
    .partners-banner__text {
      grid-area: text;
    }
    .partners-banner__link {
      grid-area: link;
    }
  }
}
