// Headings
//
// <p class="callout callout--warning">
//   <strong>Note:</strong> Headings are an important part of the structure of a page and
//   <a href="https://www.w3.org/WAI/tutorials/page-structure/headings/">should be ranked in order</a> for best results with accessibility and
//   search engine optimization.
// </p>
//
// These styles are also applied to the
// <a href="/styleguide/section-utilities/#kssref-utilities-cosmetic-heading-style">heading utility classes</a>, a couple of uses of which
// are shown in the examples below.
//
// Markup:
// <h1>Heading rank/level 1</h1>
// <h2 class="u-underline">Heading level 2 with optional .u-underline class applied</h2>
// <h3>Heading level 3</h3>
// <h3 class="u-h4">Heading level 3 with optional .u-h4 class applied, to visually take it down a notch</h3>
// <h4 class="u-text-blue">An actual heading level 4 with optional .u-text-blue class applied</h4>
// <h5>Heading level 5</h5>
// <p class="u-h3">Paragraph element with optional .u-h3 class applied, just to make it look more impressive</p>
//
// Style guide: base.headings

h1 {
  .h1-style();
  color: inherit;
}
h2 {
  .h2-style();
  color: inherit;
}
h3 {
  .h3-style();
  color: inherit;
}
h4 {
  .h4-style();
}
h5 {
  .h5-style();
}


// Consecutive heading spacing management
h1,
h2 {
  * + & {
    margin-top: 0.75em;
  }
}
h3,
h4 {
  * + & {
    margin-top: 1em;
  }
}

// There should be no need to include heading classes like .u-h1 in 
// this declaration – actual heading tags must always stay in order, 
// regardless of any styling classes that are applied to them
h1 + h1,
h1 + h2,
h2 + h2,
h2 + h3,
h3 + h3,
h3 + h4,
h4 + h4 {
  margin-top: 0;
}
