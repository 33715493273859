// Card
//
// The card is a widely-used interface pattern with a number of potential trip-ups for accessibility and general usage.
// This implementation takes a lot of cues from [Inclusive Components’ recommendations](https://inclusive-components.design/cards/).
//
// The card component is made to replace the link-box component used in some of our other sites.
//
// ### Using the card macro
// The macro `m.card()` accepts the following arguments:
// ```jinja2
// [% raw %][[ m.card(
//   title,
//   url,
//   bg_img='',
//   bg_position='center',
//   date='',
//   date_format='F Y',
//   img_classes='',
//   heading_level='h3'
// ) -]][% endraw %]
// ```
//
// * `title`: **Required.**
// * `url`: **Required.**
// * `bg_img`: Optional. Defaults to an empty string. Use this to directly reference an image file and avoid clutters of classes. The card
// macro is set up to assume the image is somewhere in the `static/images/` directory and you only need to supply the path from that point
// onwards, e.g. `content/alerts/alert-generic-en.jpg`.
// * `bg_position`: Optional. Defaults to `center`.
// * `date`: Optional. Defaults to an empty string. If provided, this should be in the YYYY-MM-DD format, e.g. 2021-02-19.
// * `date_format`: Optional. Defaults to `F Y`, giving the month and year for the current language. To set it differently, you can find the
// format characters you’ll need in django’s date filter reference: <https://docs.djangoproject.com/en/2.2/ref/templates/builtins/#date>
// * `img_classes`: Optional. Defaults to an empty string. If you don’t want to set `bg_img` or `bg_position`, and have made a CSS class
// name for how you want the card’s image area to be handled, this is the place to include it. In one of the examples below, the class
// `card__img--alert` is included to control the image area’s height.
// * `heading_level`: Optional. Defaults to `h3`. The card’s heading level should reflect where it sits in the page’s
// [ranking order](https://www.w3.org/WAI/tutorials/page-structure/headings/), and they’re often placed right after a rank 2 (`<h2>`)
// heading. But if a card follows a rank 3 (`<h3>`) heading, then its heading level should be set to `h4` instead.
//
// ### Example usage
// Using the macro as follows will give you the cards in the example shown below, with the `<ul>` and `<li>` elements serving as their grid
// containers.
//
// ```html
// [% raw -%]
// <ul class="l-grid">
//   <li class="l-grid__item">
//     [% call m.card(
//       title='The card’s title',
//       url='https://protectchildren.ca/',
//       heading_level='h4',
//     ) %]
//       Any description text for the card goes here
//     [% endcall %]
//   </li>
//   <li class="l-grid__item">
//     [[ m.card(
//       title='A card with a timestamp, like for an alert or something',
//       url=url('ctip-alerts'),
//       bg_img='content/alerts/alert-generic-en.jpg',
//       img_classes='card__img--alert',
//       heading_level='h4',
//     ) ]]
//   </li>
// </ul>
// [% endraw -%]
// ```
//
// Markup:
// <ul class="l-grid">
//   <li class="l-grid__item">
//     <article class="card">
//       <div class="card__img"></div>
//       <div class="card__info">
//         <h4 class="card__title">
//           <a href="https://protectchildren.ca/" class="card__link">The card’s title</a>
//         </h4>
//         <div class="card__desc">
//           Any description text for the card goes here
//         </div>
//       </div>
//     </article>
//   </li>
//   <li class="l-grid__item">
//     <article class="card">
//       <div class="card__img card__img--alert" style="background-image:url([[ static('images/content/alerts/alert-generic-en.jpg') ]])"></div>
//       <div class="card__info">
//         <time datetime="2018-08-12" class="card__date">August 2018</time>
//         <h4 class="card__title">
//           <a href="[[ url('ctip-alerts') ]]" class="card__link">A card with a timestamp, like for an alert or something</a>
//         </h4>
//       </div>
//     </article>
//   </li>
// </ul>
//
//
// Style guide: components.card

.card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  text-align: left;

  color: @grey-darker;
  border: 1px solid white;
  background-color: @grey2;
  box-shadow: @box-shadow[transparent];
  transform: translateY(0);
  outline: 1px solid transparent;
  outline-offset: @focus[offset];

  &:extend(.link-transitions);

  &:hover {
    border-color: @line-grey;
    background-color: white;
    box-shadow: @box-shadow[basic];
    transform: translateY(-0.2em);
  }
  // If a browser doesn't support :focus-within, the following applies:
  & a:not(.btn):focus {
    outline: @focus[width] solid @focus[colour];
    text-decoration: underline;
  }
  // If a browser does support :focus-within, we get full styling instead:
  &:focus-within {
    border-color: @line-grey;
    background-color: white;
    box-shadow: @box-shadow[basic];
    transform: translateY(-0.2em);
    outline: @focus[width] solid @focus[colour];
  }
  &:focus-within a:not(.btn):focus {
    outline: @focus[width] solid transparent;
    text-decoration: none;
  }
}
.card__link {
  // This position: static declaration will probably remain technically unnecessary,
  // but being explicit with the default in this case will ensure .card__link's
  // pseudo-element always keeps the right positioning parent (in this case, .card).
  position: static;
  display: inline-block;
  text-decoration: none;
  color: inherit;

  // The pseudo-element expands the link's clickable area to the whole card without
  // messing with how the link is announced for assistive devices.
  // ::before is used here so that we can still have ::after for external link icons etc.
  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: none;
    mask-image: none;
  }
}

.card__img {
  position: relative;
  display: block;
  margin-bottom: 1em;
  padding-bottom: 50%;
  border-bottom: 0.25em solid white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.card__img--lg,
.card__img--alert,
.card__img--news {
  height: 16vh;
  max-height: 14rem;
}

.card__title {
  margin: 0 0 0.75rem;
  font-size: @hsize[h4];
  font-family: @fonts[heading];
  line-height: (@line-height[heading] + 0.1);
  font-weight: bold;
}
.card__info {
  margin-bottom: 0;
  padding: 0 1em 1.25em;

  & :last-child {
    margin-bottom: 0;
  }
}
.card__date {
  display: block;
  margin: 0.5em 0;
  padding-bottom: 0.1em;
  font-weight: normal;
  font-size: 0.85em;
  border-bottom: 2px solid @line-grey;
  text-transform: capitalize;
}
.card__desc {
  padding-top: 0.5em;
  border-top: 2px solid @line-grey;

}

//add model disclaimers
.card__img--disclaimer-sg-w {
  .model-sg-w();
}
.card__img--disclaimer-sg-b {
  .model-sg-b();
}
.card__img--disclaimer-pl-w {
  .model-pl-w();
}
.card__img--disclaimer-pl-b {
  .model-pl-b();
}
