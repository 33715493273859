// .u-clearfix
//
// References the clearfix mixin, in case clearfixing with a class in the markup is more convenient.
//
// Style guide: utilities.clearfix
.u-clearfix {
  .clearfix();
}

// Positioning
//
// .u-relative  - Forces relative positioning. Useful for declaring a positioning parent.
// .u-static    - Forces static positioning. Useful for ensuring an element isn’t a positioning parent.
//
// Style guide: utilities.position
.u-relative {
  position: relative !important;
}
.u-static {
  position: static !important;
}

// Display
//
// .u-hidden        - Forces element to hide, both visually and to screen readers/assistive devices.
// .u-block         - Forces block display.
// .u-inline-block  - Forces inline-block display.
// .u-inline        - Forces inline display.
// .u-table         - Forces table display.
// .u-table-cell    - Forces table-cell display.
//
// Style guide: utilities.display
.u-hidden {
  display: none !important;
}
.u-block {
  display: block !important;
}
.u-inline-block {
  display: inline-block !important;
}
.u-inline {
  display: inline !important;
}
.u-table {
  display: table !important;
}
.u-table-cell {
  display: table-cell !important;
}

// .u-no-pseudo
//
// Removes a node’s pseudo-elements.
//
// Style guide: utilities.pseudoels
.u-no-pseudo {
  &::before,
  &::after {
    content: none !important;
  }
}

// Box dimensions
//
// .u-full-width  - Forces 100% width.
// .u-full-height - Forces 100% height.
//
// Style guide: utilities.sizing.dimensions
.u-full-width {
  width: 100%;
}
.u-full-height {
  height: 100%;
}

// Font size
//
// .u-fontsize-sm     - 0.85em
// .u-fontsize-normal - 1em
// .u-fontsize-lg     - 1.25em
//
// Style guide: utilities.sizing.fontsize
.u-fontsize-sm {      font-size: 0.85em; }
.u-fontsize-normal {  font-size: 1em; }
.u-fontsize-lg {      font-size: 1.2em; }


// Vertical alignment
//
// .u-vert-top    - Forces vertical alignment to top.
// .u-vert-middle - Forces vertical alignment to middle.
// .u-vert-bottom - Forces vertical alignment to bottom.
//
// Style guide: utilities.layout.vertical-align
.u-vert-top {
  vertical-align: top;
}
.u-vert-middle {
  vertical-align: middle;
}
.u-vert-bottom {
  vertical-align: bottom;
}

// Text/horizontal alignment
//
// .u-text-center - Forces centred text.
// .u-text-centre - Forces centred text using the Queen’s English.
// .u-text-left   - Forces left-aligned text.
// .u-text-right  - Forces right-aligned text.
//
// Style guide: utilities.layout.text-align
.u-text-center,
.u-text-centre {  text-align: center; }
.u-text-left {    text-align: left; }
.u-text-right {   text-align: right; }


// Form field/input width
//
// It’s a bit silly to have every input taking up 100% of its container’s available width. In some cases, we can help people get a sense of
// what we expect them to put into a form field by controlling its width, like if we’re asking for a postal code instead of an entire
// paragraph.
//
// These utility classes can be applied to form macros as `extra_classes`, or added manually to a field's `.form-group` wrapper.
//
// .u-inputwidth-xxs - maximum width of 10em
// .u-inputwidth-xs  - maximum width of 16em
// .u-inputwidth-sm  - maximum width of 24em
//
// Style guide: utilities.forms.form-input
.u-inputwidth-xxs {
  .input-group {
    max-width: 12em;
  }
}
.u-inputwidth-xs {
  .input-group {
    max-width: 20em;
  }
}
.u-inputwidth-sm {
  .input-group {
    max-width: 28em;
  }
}


// .u-dl-inline
//
// Makes description list pairs display inline.
//
// Markup:
// <dl class="u-dl-inline">
//   <dt>Newsgroup:</dt>
//   <dd>
//     A newsgroup is an online discussion about a particular topic. Newsgroups provide users with the ability to post messages about the
//     topic. The messages are stored and available to read for other users who subscribe to the newsgroup. Newsgroups can be accessed
//     through browser-based newsreaders (a separate website type) or they can be accessed through non-web browser-based software clients.
//   </dd>
//   <dt>Peer-to-Peer:</dt>
//   <dd>
//     Peer-to-peer (P2P) file sharing uses a software program to locate computers (peers) hosting a particular file available for download.
//     When the software finds a computer that has the requested file on its hard drive, the download begins.
//   </dd>
// </dl>
//
// Style guide: utilities.layout.dl-inline
.u-dl-inline {
  dt {
    display: inline;

    &::before {
      content: '';
      display: table;
      clear: both;
      margin-top: @global-margin-bottom;
    }
    &::after {
      content: ' ';
    }
  }
  dd {
    display: inline;
    margin-left: 0;
  }
}


// Visually hiding elements with .u-vh and family
//
// Removes an element from view and from the flow, without making it inaccessible to screen readers.
// <https://medium.com/@matuzo/writing-css-with-accessibility-in-mind-8514a0007939>
//
// Uses `!important`:
// * `.u-vh`
// * `.u-visually-hidden`
//
// Does not use `!important`:
// * `.u-vh--gentle`
// * `.u-visually-hidden--gentle`
//
// To hide an existing element completely (both from view and from screenreaders), use the [hidden] attribute.
// <https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden>
//
// Style guide: utilities.display.visually-hidden
.u-vh,
.u-visually-hidden {
  // Remove the item from normal flow
  position: absolute !important;
  // Workaround for falsely pronounced, smushed text
  white-space: nowrap !important;
  // Set it to the smallest possible size
  // (some screen readers ignore elements with zero height and width)
  width: 1px !important;
  height: 1px !important;
  // Hide overflowing content after resizing
  overflow: hidden !important;
  // Reset any property that may change the elements size
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  // Clipping defines what part of an element should be displayed.
  // Deprecated clip property for older browsers
  clip: rect(0 0 0 0);
  // clip-path for newer browsers. inset(50%) defines an
  // inset rectangle that makes the content disappear.
  clip-path: inset(50%);
  // It seems like at the moment nobody is quite sure why margin: -1px is there.
  // On top of that it seems to cause issues
  // (see: https://github.com/h5bp/html5-boilerplate/issues/1985).
  // margin: -1px;
}
.u-vh--gentle,
.u-visually-hidden--gentle {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  margin: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}



.u-list-blue-dark > li::before {
  color: @blue-dark;
}

.u-focus-blue:focus,
.u-focus-blue:not(.btn):focus {
  outline: @focus[width] solid @focus[colour] !important;
}

// Borders
//
// .u-underline       - Gives element a bottom border and a bit of padding to accommodate it. Intended mainly for headings.
// .u-underline-match - Like `.u-underline`, but the bottom border’s `color` is set to `inherit` to match the element’s text.
// .u-no-border       - Sets border-width to 0.
//
// Style guide: utilities.cosmetic.borders
.u-underline {
  padding-bottom: 0.15em;
  border-bottom: 1px solid @line-grey !important;
}
.u-underline-match {
  padding-bottom: 0.15em;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}
.u-no-border {
  border-width: 0 !important;
}

// Heading styles
//
// Heading style classes can be enormously useful when you need to separate the appearance of headings from their semantic meaning in
// the document’s structure.
//
// **Deprecated:** `.h1` `.h2` `.h3` `.h4` `.h5` — use `.u-h1` `.u-h2` etc. instead, as shown below.
//
// <p class="callout callout--warning">
//   <strong>Note:</strong> These heading style utility classes are <strong>NOT</strong> replacements for
//   <a href="/styleguide/section-base/#kssref-base-headings">HTML heading structure</a>.
//   Their only purpose is to control the appearance of text.
// </p>
//
// .u-h1 - Mimics the style of `<h1>`
// .u-h2 - Mimics the style of `<h2>`
// .u-h3 - Mimics the style of `<h3>`
// .u-h4 - Mimics the style of `<h4>`
// .u-h5 - Mimics the style of `<h5>`
//
// Markup:
// <p class="{{modifier_class}}">One weird trick to change the style of an element — doctors hate it!</p>
//
// Style guide: utilities.cosmetic.heading-style
.h1,
.u-h1 {
  .h1-style() !important;
}
.h2,
.u-h2 {
  .h2-style() !important;
}
.h3,
.u-h3 {
  .h3-style() !important;
}
.h4,
.u-h4 {
  .h4-style() !important;
}
.h5,
.u-h5 {
  .h5-style() !important;
}



// Font weight and style
//
// .u-weight-normal   - Forces normal font weight.
// .u-weight-bold     - Forces bold font weight.
// .u-style-normal    - Forces normal font style.
// .u-style-italic    - Forces italic font style.
//
// Style guide: utilities.cosmetic.font-style
.u-weight-normal {    font-weight: normal !important; }
.u-weight-bold {      font-weight: bold !important; }
.u-style-normal {     font-style: normal !important; }
.u-style-italic {     font-style: italic !important; }

// Text casing
//
// .u-uppercase - Forces uppercase/all-caps text.
// .u-lowercase - Forces lowercase text.
//
// Style guide: utilities.cosmetic.casing
.u-uppercase {        text-transform: uppercase !important; }
.u-lowercase {        text-transform: lowercase !important; }


// Text colours
//
// Colors:
// .u-text-white:       white
// .u-text-grey-dark:   hsl(11, 3%, 27%)
// .u-text-blue:        #007dbb
// .u-text-blue-light:  #52b1e0
// .u-text-blue-dark:   #005c9a
// .u-text-red:         #a30f23
// .u-text-red-dark:    #8c0d1e
//
// Style guide: utilities.colour.text
.u-text-white {
  color: white !important;
}
.u-text-grey {
  color: @grey6 !important;
}
.u-text-grey-dark {
  color: @grey-dark !important;
}
.u-text-blue {
  color: @blue !important;
}
.u-text-blue-light {
  color: @blue-light !important;
}
.u-text-blue-dark {
  color: @blue-dark !important;
}
.u-text-red {
  color: @red !important;
}
.u-text-red-dark {
  color: @red-dark !important;
}

// SVG fill colours
//
// Colors:
// .u-fill--inherit:    currentColor
// .u-fill--white:      white
// .u-fill--grey:       hsl(11, 3%, 40%)
// .u-fill--grey-dark:  hsl(11, 3%, 27%)
// .u-fill--blue:       #007dbb
// .u-fill--blue-light: #52b1e0
// .u-fill--blue-dark:  #005c9a
// .u-fill--red:        #a30f23
// .u-fill--red-dark:   #8c0d1e
//
// Style guide: utilities.colour.svg-fill
.u-fill--inherit {
  fill: currentColor !important;
}
.u-fill--white {
  fill: white !important;
}
.u-fill--grey {
  fill: @grey5 !important;
}
.u-fill--grey-dark {
  fill: @grey-dark !important;
}
.u-fill--blue {
  fill: @blue !important;
}
.u-fill--blue-light {
  fill: @blue-light !important;
}
.u-fill--blue-dark {
  fill: @blue-dark !important;
}
.u-fill--red {
  fill: @red !important;
}
.u-fill--red-dark {
  fill: @red-dark !important;
}

// Shadows
//
// .u-shadow-inset-top    - Insets a box-shadow at the top of the element.
// .u-shadow-inset-bottom - Insets a box-shadow at the bottom of the element.
// .u-shadow-inset-y      - Insets box-shadows at the top and bottom of the element.
//
// Style guide: utilities.cosmetic.shadow
.u-shadow-inset-top {
  box-shadow: @box-shadow[inset-lg-top];
}
.u-shadow-inset-bottom {
  box-shadow: @box-shadow[inset-lg-bottom];
}
.u-shadow-inset-y {
  box-shadow: @box-shadow[inset-lg-top], @box-shadow[inset-lg-bottom];
}

// Opacity
//
// .u-op-0  - Sets opacity to 0.
// .u-op-30 - Sets opacity to 0.3/30%.
// .u-op-50 - Sets opacity to 0.5/50%.
// .u-op-70 - Sets opacity to 0.7/70%.
//
// Style guide: utilities.cosmetic.opacity
.u-op-0 {
  opacity: 0 !important;
}
.u-op-30 {
  opacity: 0.3 !important;
}
.u-op-50 {
  opacity: 0.5 !important;
}
.u-op-70 {
  opacity: 0.7 !important;
}

// Target highlighting
//
// Style guide: utilities.cosmetic.target-highlight
.u-target-highlight {
  &:target {
    animation: 2s target-highlight-bg;
  }
}
@keyframes target-highlight-bg {
  0% {
    background-color: transparent;
  }
  5%, 50% {
    background-color: hsla(204, 100%, 31%, 0.3);
  }
  100% {
    background-color: transparent;
  }
}


// Utility spacing
//
// Example classes (see some live examples below):
// - `u-mt-lg`: apply large top margin
// - `u-px-sm`: apply a small amount of padding on the left and right
// - `u-mb-0`: get rid of bottom margin
// - `u-p-md`: apply medium padding on all four sides
//
// Available measures:
// - `0`: 0, predictably
// - `xs`: 0.2em
// - `sm`: 0.5em
// - `md`: 1.25em
// - `lg`: 2em
// - `xl`: 3em
//
// Apply in one direction:
// - `mt`: margin-top
// - `mr`: margin-right
// - `mb`: margin-bottom
// - `ml`: margin-left
// - `pt`: padding-top
// - `pr`: padding-right
// - `pb`: padding-bottom
// - `pl`: padding-left
//
// Or make it two-dimensional:
// - `mx`: applies margin along x-axis (both left and right)
// - `my`: applies margin along y-axis (both top and bottom)
// - `px`: applies padding along x-axis (both left and right)
// - `py`: applies padding along y-axis (both top and bottom)
//
// Or use just `m` or `p` without specifying direction to apply the same amount of margin or padding on all four sides of the element.
//
// Markup:
// <div class="callout callout--blue u-p-xl">
//   This callout has the class <code>.u-p-xl</code> applied to it, giving it extra-large padding on all sides (3em).
// </div>
// <div class="callout callout--blue u-mx-lg u-pl-sm">
//   This callout has the classes <code>.u-mx-lg</code> and <code>.u-pl-sm</code> applied to it, giving it large margins on its left and
//   right (2em), and small padding on its left (0.5em).
// </div>
//
// Style guide: utilities.spacing

@u-xs-sp: 0.2em;
@u-sm-sp: 0.5em;
@u-md-sp: 1.25em;
@u-lg-sp: 2em;
@u-xl-sp: 3em;

.u-grid-gap-0 {   grid-gap: 0 !important; }
.u-grid-gap-xs {  grid-gap: @u-xs-sp !important; }
.u-grid-gap-sm {  grid-gap: @u-sm-sp !important; }
.u-grid-gap-md {  grid-gap: @u-md-sp !important; }
.u-grid-gap-lg {  grid-gap: @u-lg-sp !important; }
.u-grid-gap-xl {  grid-gap: @u-xl-sp !important; }

.u-m-0 {  margin: 0 !important; }
.u-m-xs { margin: @u-xs-sp !important; }
.u-m-sm { margin: @u-sm-sp !important; }
.u-m-md { margin: @u-md-sp !important; }
.u-m-lg { margin: @u-lg-sp !important; }
.u-m-xl { margin: @u-xl-sp !important; }

.u-mx-0 {  margin-left: 0 !important;        margin-right: 0 !important; }
.u-mx-xs { margin-left: @u-xs-sp !important; margin-right: @u-xs-sp !important; }
.u-mx-sm { margin-left: @u-sm-sp !important; margin-right: @u-sm-sp !important; }
.u-mx-md { margin-left: @u-md-sp !important; margin-right: @u-md-sp !important; }
.u-mx-lg { margin-left: @u-lg-sp !important; margin-right: @u-lg-sp !important; }
.u-mx-xl { margin-left: @u-xl-sp !important; margin-right: @u-xl-sp !important; }

.u-my-0 {  margin-top: 0 !important;        margin-bottom: 0 !important; }
.u-my-xs { margin-top: @u-xs-sp !important; margin-bottom: @u-xs-sp !important; }
.u-my-sm { margin-top: @u-sm-sp !important; margin-bottom: @u-sm-sp !important; }
.u-my-md { margin-top: @u-md-sp !important; margin-bottom: @u-md-sp !important; }
.u-my-lg { margin-top: @u-lg-sp !important; margin-bottom: @u-lg-sp !important; }
.u-my-xl { margin-top: @u-xl-sp !important; margin-bottom: @u-xl-sp !important; }

.u-mt-0 {  margin-top: 0 !important; }
.u-mt-xs { margin-top: @u-xs-sp !important; }
.u-mt-sm { margin-top: @u-sm-sp !important; }
.u-mt-md { margin-top: @u-md-sp !important; }
.u-mt-lg { margin-top: @u-lg-sp !important; }
.u-mt-xl { margin-top: @u-xl-sp !important; }

.u-mr-0 {  margin-right: 0 !important; }
.u-mr-xs { margin-right: @u-xs-sp !important; }
.u-mr-sm { margin-right: @u-sm-sp !important; }
.u-mr-md { margin-right: @u-md-sp !important; }
.u-mr-lg { margin-right: @u-lg-sp !important; }
.u-mr-xl { margin-right: @u-xl-sp !important; }

.u-mb-0 {  margin-bottom: 0 !important; }
.u-mb-xs { margin-bottom: @u-xs-sp !important; }
.u-mb-sm { margin-bottom: @u-sm-sp !important; }
.u-mb-md { margin-bottom: @u-md-sp !important; }
.u-mb-lg { margin-bottom: @u-lg-sp !important; }
.u-mb-xl { margin-bottom: @u-xl-sp !important; }

.u-ml-0 {  margin-left: 0 !important; }
.u-ml-xs { margin-left: @u-xs-sp !important; }
.u-ml-sm { margin-left: @u-sm-sp !important; }
.u-ml-md { margin-left: @u-md-sp !important; }
.u-ml-lg { margin-left: @u-lg-sp !important; }
.u-ml-xl { margin-left: @u-xl-sp !important; }


.u-p-0 {  padding: 0 !important; }
.u-p-xs { padding: @u-xs-sp !important; }
.u-p-sm { padding: @u-sm-sp !important; }
.u-p-md { padding: @u-md-sp !important; }
.u-p-lg { padding: @u-lg-sp !important; }
.u-p-xl { padding: @u-xl-sp !important; }

.u-px-0 {  padding-left: 0 !important;        padding-right: 0 !important; }
.u-px-xs { padding-left: @u-xs-sp !important; padding-right: @u-xs-sp !important; }
.u-px-sm { padding-left: @u-sm-sp !important; padding-right: @u-sm-sp !important; }
.u-px-md { padding-left: @u-md-sp !important; padding-right: @u-md-sp !important; }
.u-px-lg { padding-left: @u-lg-sp !important; padding-right: @u-lg-sp !important; }
.u-px-xl { padding-left: @u-xl-sp !important; padding-right: @u-xl-sp !important; }

.u-py-0 {  padding-top: 0 !important;        padding-bottom: 0 !important; }
.u-py-xs { padding-top: @u-xs-sp !important; padding-bottom: @u-xs-sp !important; }
.u-py-sm { padding-top: @u-sm-sp !important; padding-bottom: @u-sm-sp !important; }
.u-py-md { padding-top: @u-md-sp !important; padding-bottom: @u-md-sp !important; }
.u-py-lg { padding-top: @u-lg-sp !important; padding-bottom: @u-lg-sp !important; }
.u-py-xl { padding-top: @u-xl-sp !important; padding-bottom: @u-xl-sp !important; }

.u-pt-0 {  padding-top: 0 !important; }
.u-pt-xs { padding-top: @u-xs-sp !important; }
.u-pt-sm { padding-top: @u-sm-sp !important; }
.u-pt-md { padding-top: @u-md-sp !important; }
.u-pt-lg { padding-top: @u-lg-sp !important; }
.u-pt-xl { padding-top: @u-xl-sp !important; }

.u-pr-0 {  padding-right: 0 !important; }
.u-pr-xs { padding-right: @u-xs-sp !important; }
.u-pr-sm { padding-right: @u-sm-sp !important; }
.u-pr-md { padding-right: @u-md-sp !important; }
.u-pr-lg { padding-right: @u-lg-sp !important; }
.u-pr-xl { padding-right: @u-xl-sp !important; }

.u-pb-0 {  padding-bottom: 0 !important; }
.u-pb-xs { padding-bottom: @u-xs-sp !important; }
.u-pb-sm { padding-bottom: @u-sm-sp !important; }
.u-pb-md { padding-bottom: @u-md-sp !important; }
.u-pb-lg { padding-bottom: @u-lg-sp !important; }
.u-pb-xl { padding-bottom: @u-xl-sp !important; }

.u-pl-0 {  padding-left: 0 !important; }
.u-pl-xs { padding-left: @u-xs-sp !important; }
.u-pl-sm { padding-left: @u-sm-sp !important; }
.u-pl-md { padding-left: @u-md-sp !important; }
.u-pl-lg { padding-left: @u-lg-sp !important; }
.u-pl-xl { padding-left: @u-xl-sp !important; }
