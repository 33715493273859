.twenty-heading-h1 {
  color: @blue-dark;
  font-weight: normal;
  font-size: 3.4em;
  text-align: center;
  padding-bottom: .5em;

  span {
    color: @blue;
    font-weight: bold;
    font-size: 1.2em;
  }
}

.twenty-heading-h2 {
  color: @blue;
  font-weight: normal;
  font-size: 3em;
  text-align: center;
  width: 100%;
}

.twenty-years {
  .fade {
    width: 90%;
    margin: auto;
  }
}

.twenty-intro {
  display: flex;
  width: 90%;
  margin: auto;
  align-items: center;
  .twenty-video, .twenty-copy {
    width: 50%;
  }
  .twenty-copy {
    padding: 0 50px;
  }
}

.quote-container {
  height: 100vh;
  scroll-snap-align: start;
}

.twenty-quote {
  color: white;
  font-style: italic;
  text-align: left;
  font-weight: bolder;
  font-size: 1.3em;
  line-height: 2em;

  span {
    font-family: @fonts[heading];
    font-weight: normal;
    font-size: 1.3em;
  }
}

.twenty-img {
  background-repeat: no-repeat;
  background-size: cover;
}

.quote1 {
  background-image: url('@{image-path}content/20anniversary/ctip20_quote1.jpg');
  display: none;
}

.quote2 {
  background-image: url('@{image-path}content/20anniversary/ctip20_quote2.jpg');
  display: none;
}

.quote3 {
  background-image: url('@{image-path}content/20anniversary/ctip20_quote3.jpg');
  display: none;
}

.blue_bg {
  background-image: url('@{image-path}content/20anniversary/ctip20_bg_blue.jpg');
}

.white_bg {
  background-image: url('@{image-path}content/20anniversary/ctip20_bg_white.jpg');
  background-attachment: fixed;
}

.red_bg {
  background-image: url('@{image-path}content/20anniversary/ctip20_bg_red.jpg');
}

.twenty-copy {
  color: white;
  text-align: center;
  font-weight: normal;
  line-height: 1.6em;
  font-size: 1.3em;
  a {
    color: @blue-light;
    text-decoration-color: @blue-light;
  }
}

.twenty-partners {
  color: @grey6;
  text-align: left;
  font-weight: normal;
  line-height: 1.5em;
  font-size: 1em;

  span {
    font-family: @fonts[heading];
    font-size: 1.3em;
  }
}

.twenty-btn {
  text-transform: none;
  font-size: 1.1em;
}

.twenty-stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 90%;
  margin: auto;
}

.twenty-stats {
  padding: 1.25rem;
  text-align: left;
  font-family: @fonts[heading];
  font-size: 2em;
  line-height: 1.2;
  font-weight: normal;
  width: 33%;
  padding-left: 3em;
  p {
    height: 200px;
    .highlight {
      font-size: 1.5em;
      line-height: 1;
      color: @red;
      font-weight: bold;
    }
  }
}

.exclamation-mark {
  display: block;
  margin-left: -3em;
  margin-bottom: -5em;
}

.exclamation-mark-div {
  display: block;
}

.twenty-kindwords-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.twenty-kindwords {
  margin-bottom: 50px;
  width: 40%;
  margin-right: 30px;
  border-radius: 10px;
  padding: 20px;
  background-color: @blue;
  &:last-child {
    margin-bottom: 0;
  }
  .link:not(.btn) {
    color: #fff;
    text-decoration: none;
    &:focus {
      outline: none;
    }
    p {
      text-align: center;
      margin-bottom: 0;
      margin-top: 10px;
      span {
        background-color: #fff;
        color: @blue;
        border-radius: 10px;
        padding: 5px 8px;
        display: inline-block;
      }
    }
  }
}

.twenty-start-container {
  width: 50%;
  margin: auto;
  padding-top: 150px;
}
.twenty-start {
  padding: 75px 0;
}

@media screen and (max-width: 500px) {
  .quote1 {
    background-position: center right;
  }
  .quote2 {
    background-position: center left;
  }
  .quote3 {
    background-position: top right -200px;
  }
  .twenty-years {
    p {
      line-height: 32px;
    }
  }
  .twenty-intro, 
  .twenty-stats-container {
    display: block;
    height: auto;
  }
  .twenty-stats-container {
    .twenty-heading-h2 {
      padding-top: 40px;
    }
  }
  .twenty-intro {
    &.fade-item {
      position: static;
      transform: none;
      padding: 40px 0;
    }
    .twenty-video, .twenty-copy {
      width: 90%;
      margin: auto;
    }
  }
  .twenty-stats {
    width: 90%;
  }
  .twenty-start-container {
    width: 90%;
    padding-top: 50px;
  }
  .twenty-kindwords-container {
    width: 90%;
    display: block;
  }
  .twenty-kindwords {
    width: 100%;
  }
}
