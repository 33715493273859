.map-select {
  position: relative;
  .selected-province {
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: bold;
  }
}

.canadamap {
  max-width: 100%;

  #map-bc path,
  #map-ab path,
  #map-mb path,
  #map-nl path,
  #map-ns path,
  #map-pei path,
  #map-sk path {
    transition: fill @t[basic] ease;
  }

  // Removes hover state from iOS Safari which prevented active state from showing on single touch
  @media (hover:hover) {
    #map-bc:hover path,
    #map-ab:hover path,
    #map-mb:hover path,
    #map-nl:hover path,
    #map-ns:hover path,
    #map-pei:hover path,
    #map-sk:hover path {
      fill: @blue-dark;
      transition: fill @t[basic] ease;
      cursor: pointer;
    }
  }

  #map-pei circle {
    fill: #fff;
  }

  .prov {
    fill: @grey3;
    // transition: fill @t[basic] ease;

    &.is-enabled {
      fill: @blue;
      cursor: pointer;
    }
    &.is-active {
      fill: @red;
    }
  }
}

@media screen and (max-width: 40em) {
  .canadamap {
    display: none;
  }
}
