// List utility classes
//
// Style guide: utilities.list

.list--simple {
  list-style: none;
  margin-left: 0;
}

.list--inline {
  margin-left: 0;
  padding-left: 0;

  > li {
    display: inline-block;
  }
}

.list--spaced {
  > li {
    margin-bottom: 1.25rem;
  }
  > li > ul:not(.l-grid),
  > li > ol:not(.l-grid) {
    margin-top: 1.25rem;
  }
}
.list--spaced-xs {
  > li {
    margin-bottom: 0.3em;
  }
  > li > ul:not(.l-grid),
  > li > ol:not(.l-grid) {
    margin-top: 0.3em;
  }
}
.list--spaced-sm {
  > li {
    margin-bottom: 0.6em;
  }
  > li > ul:not(.l-grid),
  > li > ol:not(.l-grid) {
    margin-top: 0.6em;
  }
}

.list--tiled {
  list-style: none;
  margin-left: 0;

  > li {
    .callout-base(
      @padding: 0.5em 0.5em 0.5em 0.75em;
      @border-width: 0.2em;
    );

    margin: 0.25em;
    background-color: @grey2;
    border-color: @grey3;
  }
}
@supports (display: grid) {
  .list--tiled {
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));

    > li {
      margin: 0;
    }
  }
}


ol[type="a"],
ol[type="A"] {
  list-style: none;
  counter-reset: li;

  > li {
    position: relative;
  }
  > li::before {
    position: absolute;
    right: 100%;
    margin-right: 0.35em;
    counter-increment: li;
    text-align: right;
  }
}
// Attribute selectors aren't case-sensitive but we only ever want lower-alpha in nested lists anyway
ol ol[type="a"],
ul ol[type="a"] {
  > li::before {
    content: counter(li, lower-alpha) ")";
  }
}
ol[type="A"] {
  > li::before {
    content: counter(li, upper-alpha) ")";
  }
}

.bold-numbers {
  > li::marker {
    font-weight: bold;
  }
}
.big-numbers { // https://css-tricks.com/numbering-in-style/
  counter-reset: li;
  padding-left: 0;
  margin-top: 1em;
  margin-left: calc(1.3em + 2 * ((100vw - 300px) / 200));

  > li {
    position: relative;
    margin-left: 0.4em;
    list-style: none;

    &::before {
      position: absolute;
      right: 100%;
      margin-right: 0.15em;
      content: counter(li) ".";
      counter-increment: li;
      font-family: @fonts[heading];
      // font-weight: bold;
      line-height: @line-height[heading]; // matching heading line-height
      text-align: right;
    }
  }
}
.big-numbers--upper-alpha > li::before {
  content: counter(li, upper-latin) ")";
}
.big-numbers--lower-alpha > li::before {
  content: counter(li, lower-latin) ")";
}
.big-numbers--h1 > li::before {
  font-size: @hsize[h1];
  // color: @h1-colour;
}
.big-numbers--h2 > li::before {
  font-size: @hsize[h2];
  // color: @h2-colour;
}
.big-numbers--h3 > li::before {
  font-size: @hsize[h3];
  font-weight: bold;
  // color: @h3-colour;
}
.big-numbers--h4 > li::before {
  font-size: @hsize[h4];
  font-weight: bold;
  line-height: @line-height[heading] + 0.1;
  // color: @h3-colour;
}

.sublist-numbers {
      display: block;
      float: left;
      width: 1.2em;
      margin-left: -1.2em;
}

.sublist {
  margin-left: 2.2em;
}
