// Footnote
//
// ### Using the footnote macros
// The macro `m.fn_link()` is used within the body text to visually refer to and link to its corresponding footnote. It accepts three
// arguments:
// ```jinja2
// [% raw %][[ m.fn_link(id, sup, self_id) -]][% endraw %]
// ```
// * `id`: **Required.** This is the number/symbol/whatever that will link to the corresponding footnote.
// * `sup`: Optional. Defaults to `true`. Controls whether the footnote link is wrapped in a superscript tag (`<sup>`).
// * `self_id`: Optional. Defaults to `true`. Enables the corresponding footnote to link back to the footnote link’s place in the text. If
//   there are two or more instances of footnote links on a page that all refer to the same footnote, `self_id` should be set as `false` for
//   all of them except the first.
//
// The macro `m.footnote()` accepts one argument:
// ```jinja2
// [% raw %][% call m.footnote(id) -%]
//   Footnote content
// [% endcall -%][% endraw %]
// ```
// * `id`: **Required.** This is the number/symbol/whatever that is used to link to the footnote, and it **must match** the `id` value set
//   in the corresponding `m.fn_link()`.
// 
//
// ### Example usage
//
// ```html
// [% raw -%]
// <p>
//   Studies show that 13% (which amounts to roughly 1 in 7) of children aged 10–17 will receive unwanted sexual solicitations
//   online,[[m.fn_link('1')]] and that 26% of children send personal information to unknown people.[[m.fn_link('2')]] Zoe & Molly Online,
//   through online comics, an interactive game and quiz, not only gives kids the opportunity to have some fun while increasing safety
//   confidence, but also lets them practice online safety skills that gives them the tools and knowledge in order to respond to unwanted
//   solicitation online and to mitigate risk.
// </p>
//
// <hr>
//
// <ol class="footnotes">
//   [% call m.footnote('1') -%]
//     Wolak, J., Mitchell, K., and Finkelhor, D. (2006). “Online victimization of youth: Five years later.” <cite>National Center for
//     Missing & Exploited Children Bulletin</cite> - #07-06-025. Alexandria, VA.
//   [% endcall -%]
//
//   [% call m.footnote('2') -%]
//     Wolak, J., Finkelhor, D., Mitchell, K., & Ybarra, M. (2008). “Online ‘Predators’ and their Victims: Myths, Realities and
//     Implications for Prevention and Treatment.” <cite>American Psychologist</cite>, 63, 111-128.
//   [% endcall -%]
// </ol>
// [% endraw -%]
// ```
//
// Markup:
// <p>
//   Studies show that 13% (which amounts to roughly 1 in 7) of children aged 10–17 will receive unwanted sexual solicitations
//   online,<sup><a id="ref1" href="#fn1" class="footnote__ref" title="Go to footnote 1">1</a></sup> and that 26% of children send personal
//   information to unknown people.<sup><a id="ref2" href="#fn2" class="footnote__ref" title="Go to footnote 2">2</a></sup> Zoe & Molly
//   Online, through online comics, an interactive game and quiz, not only gives kids the opportunity to have some fun while increasing
//   safety confidence, but also lets them practice online safety skills that gives them the tools and knowledge in order to respond to
//   unwanted solicitation online and to mitigate risk.
// </p>
// <hr>
// <ol class="footnotes">
//   <li id="fn1" class="footnote u-target-highlight">
//     <span class="footnote__id">1</span>
//     Wolak, J., Mitchell, K., and Finkelhor, D. (2006). “Online victimization of youth: Five years later.” <cite>National Center for
//     Missing & Exploited Children Bulletin</cite> - #07-06-025. Alexandria, VA.
//     <a class="footnote__return" href="#ref1" title="Back to reference 1">&#8617;</a>
//   </li>
//   <li id="fn2" class="footnote u-target-highlight">
//     <span class="footnote__id">2</span>
//     Wolak, J., Finkelhor, D., Mitchell, K., & Ybarra, M. (2008). “Online ‘Predators’ and their Victims: Myths, Realities and
//     Implications for Prevention and Treatment.” <cite>American Psychologist</cite>, 63, 111-128.
//     <a class="footnote__return" href="#ref2" title="Back to reference 2">&#8617;</a>
//   </li>
// </ol>
//
//
// Style guide: components.footnote

.footnotes {
  margin: 1.5em 0 0;
  font-size: 0.85em;
  list-style: none;
}
.footnote {
  position: relative;
  padding: 0.65rem 0 0.75rem 1.75em;
  padding-left: calc(1.75em + 1 * ((100vw - 300px) / 200));
}
.footnote__id {
  position: absolute;
  right: 100%;
  margin-right: -1.5em;
}
.footnote__return {
  margin-left: 0.2em;
}
.footnote__return,
.footnote__ref {
  margin: 0 0.1em;
  padding: 0 0.35em;
  border-radius: 0.25em;
  background-color: darken(@grey2, 5%);
  text-decoration: none;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    background-color: @grey3;
  }
}
