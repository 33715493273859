@site-header-border-width: 8px;

.site-header {
  position: relative;
  z-index: @z[header];
  flex: 0 0 auto;
  color: @blue-dark;
  box-shadow: 0 0 1em hsla(0, 0%, 0%, 0.5);
}
.site-header__container {
  position: relative;
  display: table;
  height: @header-sm-height;
  padding-top: 0.8em;
  border-bottom: @site-header-border-width solid @blue;
  background-color: white;
  box-shadow: @box-shadow[transparent];

  transition: color @t[basic], background-color @t[basic], box-shadow @t[basic];

  &.is-open-menu {
    color: white;
    background-color: darken(@blue-dark, 10%);
    box-shadow: 0 0 1em hsla(0, 0%, 0%, 0.5);
  }
}
.site-header__logo-wrapper,
.site-header__nav {
  display: table-cell;
  vertical-align: bottom;
}
.site-header__logo-wrapper {
  padding: 0 1em 0.1em 0;
}
.site-header__logo {
  max-height: 2.6rem;
  width: 100%;
  max-width: 13rem;
  text-align: left;
  
  // fallback colour for browsers that don't support custom variables:
  fill: @logo-blue-dark;
  
  --blue-light: @logo-blue;
  --blue-dark: @logo-blue-dark;

  transition: fill @t[basic];

  [lang="fr"] & {
    max-width: 14rem;
  }
  
  .is-open-menu & {
    fill: white;
    
    --blue-light: white;
    --blue-dark: white;
  }
}

.site-header__tagline {
  margin-bottom: 0;
  max-width: 27em;
  font-size: 0.65em;
  font-weight: bold;
  text-transform: uppercase;
  
  [lang="fr"] & {
    max-width: 29em;
  }
  
  @media screen and @bp[header-sm] {
    line-height: @line-height[heading] + 0.2;
    .u-visually-hidden--gentle();
  }
}



@media screen and @bp[header-lg] {
  .site-header {
    padding: 0.75em 0 0;
    border-bottom: @site-header-border-width solid @blue;
  }
  .site-header__container {
    display: block;
    height: auto;
    padding-top: 0;
    border-bottom: none;
  }
  .site-header__nav {
    position: static;
    display: block;
  }
  .site-header__logo-wrapper {
    display: block;
    padding: 0 1em 0 0;
    max-width: 20rem;
  }
  .site-header__logo {
    width: 100%;
    max-width: 17rem;
    max-height: 4em;
    
    [lang="fr"] & {
      max-width: 18.5rem;
    }
  }
}



@supports (display: flex) {
  @media screen and (min-width: 35em) and @bp[header-sm] {
    .site-header__logo-wrapper {
      display: flex;
      align-items: flex-end;
    }
    .site-header__logo {
      flex: 0 1 auto;
      min-width: 11em;
    }
    .site-header__tagline {
      flex: 1 1 auto;
      margin: 0 0 0.8em 1.5em;
      font-size: 0.6em;
      
      // override .u-visually-hidden--gentle
      position: static;
      white-space: normal;
      width: auto;
      height: auto;
      overflow: auto;
      clip: none;
      clip-path: none;
    }
  }
}
