.results {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 1px;
  margin: 1.5em 0 3em;
  text-align: center;
  background-color: @grey3;
}

.result {
  flex: 1 0 40%;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  min-width: 14em;
  background-color: white;
}

// For a few of the result items, having a container inside
// the flex item is helpful for positioning:
.result__container {
  position: relative;
}

.result__text {
  display: block;
  font-family: @fonts[heading];
  line-height: @line-height[heading];
  font-size: 1.5em;
  letter-spacing: 0.02em;
  font-weight: bold;
  text-transform: uppercase;
  color: @grey-darker;
}
.result__text--sm {
  font-size: 1.25em;
}
.result__text--lg {
  font-size: 3em;
}

.result__icon {
  display: inline-block;
  max-width: 100%;

  .blue {
    .u-fill--blue();
  }
  .blue-light {
    .u-fill--blue-light();
  }
  .white {
    .u-fill--white();
  }
  .grey {
    fill: @grey-darker;
  }

  text,
  foreignObject {
    font-family: @fonts[heading];
    line-height: @line-height[heading];
    letter-spacing: 0.02em;
    font-weight: bold;
  }
  tspan {
    fill: @blue-light;
  }
}


// Styling for specific results graphics

.result--reports-processed {
  .result__icon {
    width: 14rem;
    height: 11.7rem;

    foreignObject {
      font-size: 20px;
      text-transform: uppercase;
      text-align: center;
      color: white;
    }
  }
}


.result__text--resources-number {
  align-self: stretch;
  font-size: 2.75em;
  line-height: 1;
  padding: 0.2em 0.5em 0.3em;
  // optically adjust for the appended +:
  text-transform: none;
  color: white;
  background-color: @grey-darker;
}
.result__text--resources-desc {
  padding: 0.25em 1em 0.5em;
  background-color: white;
  border: 0.25em solid @grey-darker;
}


.result__text--educational-views-desc {
  margin-bottom: 0.5rem;
}
.result__text--educational-views-number {
  font-size: 2.75em;
}


.result__text--forwarded-lea {
  width: 63%;
  margin-bottom: -0.75em;
  text-align: left;
  align-self: flex-start;
}
.result__icon--forwarded-lea {
  margin-right: -1em;

  text {
    // Counter-intuitive, but...
    // font-size for svg text elements should be declared in px to scale properly,
    // not relative units like em or rem!
    font-size: 55px;
  }
}


.result__container--action-international {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.result__text--action-international-desc {
  flex: 0 1 100%;
  margin-bottom: 0.5rem;
}
.result__icon--action-international {
  flex: 0 1 40%;
  margin: 0 -0.5rem;
  height: auto;
  width: auto;
}
.result__text--action-international-number {
  flex: 0 1 50%;
  padding-left: 0.5rem;
  font-size: 3.5em;
  line-height: 1;
}


.result__text--luring-number,
.result__text--sextortion-number,
.result__text--ncdii-number {
  font-size: 2.5em;
  // Bit of optical alignment:
  margin-right: -0.3em;
}


.result__text--forwarded-cw-desc {
  margin: 0 2rem 1rem;
}
.result__text--forwarded-cw-number {
  position: relative;
  font-size: 3.5em;
  padding: 0 2rem 0.7rem 3rem;
  border-radius: 0.7rem;
  background-color: @grey-darker;
  color: white;
}
.result__icon--forwarded-cw {
  position: absolute;
  top: -0.7rem;
  left: -2rem;
  width: 5rem;
  height: 5rem;
}


.result__container--arrests {
  align-self: flex-start;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  background-color: @grey-darker;
  color: white;

  .result__text {
    padding: 0.7rem 1rem;
    text-align: left;
    color: inherit;
  }
}
.result__text--arrests-desc {
  flex: 0 1 100%;
}
.result__text--arrests-number {
  flex: 0 1 auto;
  padding-top: 0;
  font-size: 4em;
  line-height: 1;
}
.result__icon--arrests {
  flex: 1 1 45%;
  max-width: 12em;
  margin-top: -1em;
  margin-left: -1em;
  transform: translateY(1em) translateX(1em) scale(1.1);
}


.result__icon--children-protected {
  display: block;
  font-family: @fonts[heading];
  font-size: 1.8em;
  margin: 0 auto;
  width: 6.2em;
  height: 6.2em;
  
  text {
    font-size: 33px;
  }
}


.result--luring {
  container-name: result-luring;
  container-type: inline-size;
}
@container result-luring (min-width: 16em) {
  .result__container {
    display: grid;
    grid-template-columns: 5fr 4fr;
    align-items: center;
    text-align: left;
  }
  .result__icon {
    grid-row: span 2;
    padding-right: 0.5em;
    width: 100%;
  }
  .result__text {
    grid-column: 2 / -1;
  }
}


.result__text--arachnid-processed-number,
.result__text--arachnid-review-number,
.result__text--arachnid-providers-number,
.result__text--arachnid-notices-number {
  font-size: 2.5em;
  line-height: 1;
  margin-bottom: 0.07em;
}

.result__icon--arachnid-processed,
.result__icon--arachnid-review,
.result__icon--arachnid-providers,
.result__icon--arachnid-notices {
  margin-bottom: 0.5rem;
}

.result__icon--arachnid-notices {
  // Optical alignment
  margin-right: -1em;
}

.result__icon--arachnid-review {
  text {
    font-size: 36px;
  }
}
