.autocomplete {
  position: relative;

  &:focus-within [role="combobox"] {
    // Use partial input focus styling to indicate focus is still within
    // the component (i.e. on the autocomplete's menu), even if not on the
    // combobox input itself.
    border-color: @form-colour[border-focused];
  }

  [role="listbox"] {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: @btn-border-radius;
    right: @btn-border-radius;
    margin: 0;
    max-height: 12em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    color: @grey4;
    background-color: white;
    border: @form-input-border-width solid @form-colour[border-focused];
    border-top: 0;
  }

  [role="option"][aria-selected="true"],
  [role="option"]:hover,
  [role="option"]:focus {
    background-color: @blue;
    border-color: @blue;
    color: white;
    outline: none;
  }
}

.autocomplete__dropdown-arrow-down {
  position: absolute;
  display: block;
  top: 0.65em;
  right: 0.4em;
  pointer-events: none;
}

.autocomplete__option {
  position: relative;
  display: block;
  padding: 0.3em 0.5em;
  cursor: pointer;
  color: @text-colour;

  &:first-of-type {
    border-top-width: 0;
  }
  &:last-of-type {
    border-bottom-width: 0;
  }
  &:nth-child(even) {
    background-color: @grey2;
  }
  > * {
    pointer-events: none;
  }
}

.autocomplete__option--no-results {
  cursor: not-allowed;
  font-style: italic;
  background-color: @grey1;
  color: @grey6;
}
