.search-field {
  width: 100%;
  margin: 1.25em 0;
}
.search-field--header {
  text-align: center;
}

.search-field__input,
.search-field__btn {
  display: inline-block;
  vertical-align: baseline;
}

.search-field__input {
  width: 70%;
  width: calc(100% - (3.2em + 0.25rem));
  color: white;
  background-color: darken(@blue-dark, 10%);
  // background-color: darken(@blue-dark, 15%);
  border-color: @blue;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  
  &:focus {
    background-color: @blue-dark;
  }
  
  &::placeholder {
    color: white;
  }
}
.search-field__btn {
  margin-left: 0.125rem;
  width: 3.2em;
  padding: 0.5em 0.85em;
  padding: calc(0.5em + 0.125rem) 0.85em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  
  .search-field--header & {
    .btn--light();
  }
}



@media screen and @bp[header-lg] {
  .search-field--header {
    text-align: right;
  }
  .search-field__input {
    // border-color: @blue-dark;
    color: @grey-darker;
    background-color: white;
    border-color: @grey4;
    
    &:focus {
      background-color: white;
    }
    
    &::placeholder {
      color: @grey-darker;
    }
  }
  .search-field__btn {
    .search-field--header & {
      // .btn--primary();
      .btn--dark();
    }
  }
}
