// Nav menu
//
// This is the site’s header navigation and should be available on every page. At small viewport sizes, it behaves as a full-screen dialog
// when shown, using a customized fork of the [a11y-dialog script](https://a11y-dialog.netlify.app/).
//
// Normally, when a11y-dialog is used to set up a dialog, the same element is targeted to function as the focus trap container and the
// dialog container. However, the customization allows for setting up the focus trap and dialog containers as two separate elements. Sounds
// like madness, which is normally true. This shouldn’t be done without good reason. But our nav menu is that sort of an edge case. In order
// to retain the layout we have with the site header logo remaining visible, keeping the menu’s toggle button available while the menu’s
// dialog is showing is a handy way to avoid duplicating site header markup.
//
// Here’s a bare-bones example of markup where the focus trap and dialog containers are two different elements (the dialog container must
// always be nested inside the focus trap container in this case):
//
// ```html
// <!--
//   #menu is the focus trap container.
//   This means that while the dialog is shown, the menu’s show/hide button is still
//   focusable if someone tabs through the menu’s elements using their keyboard:
// -->
// <nav id="menu">
//   <button id="menu-toggle">Menu</button>
//   <!--
//     #menu-dialog is the dialog container,
//     the bit that actually gets shown and hidden:
//   -->
//   <div id="menu-dialog">
//     [Menu contents]
//   </div>
// </nav>
// ```
//
//
// Style guide: components.nav-menu

.nav-menu {
  position: fixed;
  z-index: -1;
  display: block;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &[aria-hidden="true"] {
    display: none;
  }
}

.nav-menu__container {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: darken(@blue-dark, 10%);
  color: white;

  opacity: 0;
  transition: opacity @t[basic] ease-in-out;

  .is-open & {
    opacity: 1;
  }
  .is-closing & {
    opacity: 0;
  }

  //Red REPORT nav (see base.html line 229)

  .header-nav-primary__report-link {
    text-decoration-color: @red;

    &:hover,
    &:focus,
    &:active,
    &.is-current {
      text-decoration-color: @red-light;
      color: @red-light;
    }
  }
}

.nav-menu__toggle-wrapper {
  text-align: right;
}
.nav-menu__toggle {
  font-size: 1.35em;
  padding: 0.35em 0.65em;

  // Reluctantly keeping magic numbers to define this button’s width.
  // Its purpose is to keep the width of this button consistent, so
  // it doesn’t shift when the nav menu is toggled and the button’s
  // text changes from MENU to CLOSE/FERMER.
  width: 3.2em;

  [lang="fr"] & {
    width: 3.55em;
  }

  // Prevent ugly gaps between this button and the header’s bottom
  // border on retina displays
  margin-bottom: -2px;
  border-bottom: 2px solid @blue;

  border-radius: 0.4em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: @blue;

  font-family: @fonts[heading];
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  word-break: keep-all;
  word-wrap: normal;

  &:hover,
  &:active {
    background-color: @blue-dark;
    transform: translateY(0);
    box-shadow: none;
  }
  &:focus {
    background-color: @blue-dark;
    transform: translateY(0);
    outline-color: @focus[colour];
    box-shadow:
       2px  2px 0 @focus[colour],
      -2px -2px 0 @focus[colour],
       2px -2px 0 @focus[colour],
      -2px  2px 0 @focus[colour];
  }
}
.no-js .nav-menu__toggle[data-nav-menu-target] {
  // Hide the button that does nothing without javascript
  display: none;
}
.has-js .nav-menu__toggle--fallback {
  // Hide the noscript version that links to the site’s footer menu
  display: none;
}



@media screen and @bp[header-sm] {
  .nav-menu[data-initial] {
    display: none;
  }
  // .nav-menu__inner declarations for mobile views are contained in this media query
  // because resetting its position to static in the @bp[header-lg] query is apparently
  // not sufficient to make sure it isn’t a positioning parent for .header-nav-secondary
  .nav-menu__inner {
    position: absolute;
    display: block;
    top: @header-sm-height;
    bottom: 0;
    width: 100%;
    padding: 0 1em;
    padding: 0 @side-spacing 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    transform: translateY(-7%);
    transition: transform @t[slow] ease-in-out;

    .is-open & {
      transform: translateY(0);
    }
    .is-closing & {
      transform: translateY(-7%);
    }

    & > :last-child {
      margin-bottom: 3em;
    }
  }
}
@media screen and @bp[header-lg] {
  .nav-menu {
    position: static;
    display: block;
    visibility: visible;
    height: auto;
  }
  .nav-menu__container {
    position: static;
    height: auto;
    padding-top: 0;
    background-color: transparent;
    color: inherit;
    opacity: 1;

    // Ensure .nav-menu__container's opacity is always at 1
    .is-closing & {
      opacity: 1;
    }
  }
  .nav-menu__toggle,
  .nav-menu__header {
    display: none;
  }
}
