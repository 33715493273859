// Use <span> elements for these, not <i>
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/i

.icon {
  display: inline-block;
  vertical-align: baseline;
  width: 1em;
  height: 1em;
  margin: 0 0 -0.1em;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: contain;
}

.icon--md {
  font-size: 1.125em;
  margin: -0.05em 0 -0.15em;
}

.icon--lg {
  font-size: 1.25em;
  margin: -0.05em 0 -0.2em;
}
.icon--xl {
  font-size: 1.5em;
  margin: -0.25em 0;
}
.icon--xxl {
  font-size: 2em;
  margin: -0.3em 0;
}

.icon--lower {
  margin-bottom: -0.185em;
}

