.callout-base(@padding: 1.25rem 1.5rem 1.5rem; @border-width: 0 0 0 4px;) {
  margin: 0 0 @global-margin-bottom;
  padding: @padding;
  border-style: solid;
  border-width: @border-width;

  & > :last-child {
    margin-bottom: 0;
  }
}

.clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

// These heading style mixins are applied to:
// 1. The heading element selectors declared in css/base/headings.less (h1, h2, etc.)
// 2. The heading style utility classes declared in css/utilities.less (.u-h1, .u-h2, etc.)
.h1-style() {
  font-size: @hsize[h1];
  font-family: @fonts[heading];
  font-weight: bold;
  line-height: @line-height[heading];
}
.h2-style() {
  font-size: @hsize[h2];
  font-family: @fonts[heading];
  font-weight: normal;
  line-height: @line-height[heading];
}
.h3-style() {
  font-size: @hsize[h3];
  font-family: @fonts[heading];
  font-weight: bold;
  line-height: @line-height[heading];
}
.h4-style() {
  font-size: @hsize[h4];
  font-family: @fonts[heading];
  font-weight: bold;
  line-height: @line-height[heading] + 0.1;
}
.h5-style() {
  font-size: @hsize[h5];
  font-weight: bold;
}

// model disclaimers

.model-sg-w() {
  &::before {
    position: absolute;
    content: 'Model in image. Intended as illustrative.';
    left: 0.5em;
    bottom: 0.5em;
    font-size: 0.5rem;
    font-family: @fonts[body];
    font-weight: normal;
    color: white;
    opacity: 0.75;
    text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
  }
  [lang="fr"] &::before {
    content: 'Photo d’un mannequin. Pour illustrer le texte.';
  }
}

.model-sg-b() {
  &::before {
    position: absolute;
    content: 'Model in image. Intended as illustrative.';
    left: 0.5em;
    bottom: 0.5em;
    font-size: 0.5rem;
    font-family: @fonts[body];
    font-weight: normal;
    color: black;
    opacity: 0.5;
    text-shadow:
    0.02em 0.05em 0.15em hsla(0, 100%, 100%, 1),
    0.02em 0.05em 0.25em hsla(0, 100%, 100%, 1);
  }
  [lang="fr"] &::before {
    content: 'Photo d’un mannequin. Pour illustrer le texte.';
  }
}

.model-pl-w() {
  &::before {
    position: absolute;
    content: 'Models in image. Intended as illustrative.';
    left: 0.5em;
    bottom: 0.5em;
    font-size: 0.5rem;
    font-family: @fonts[body];
    font-weight: normal;
    color: white;
    opacity: 0.75;
    text-shadow:
    0.02em 0.05em 0.15em hsla(0, 0%, 0%, 0.7),
    0.02em 0.05em 0.25em hsla(0, 0%, 0%, 0.7);
  }
  [lang="fr"] &::before {
    content: 'Photos de mannequins. Pour illustrer le texte.';
  }
}

.model-pl-b() {
  &::before {
    position: absolute;
    content: 'Models in image. Intended as illustrative.';
    left: 0.5em;
    bottom: 0.5em;
    font-size: 0.5rem;
    font-family: @fonts[body];
    font-weight: normal;
    color: black;
    opacity: 0.5;
    text-shadow:
    0.02em 0.05em 0.15em hsla(0, 100%, 100%, 1),
    0.02em 0.05em 0.25em hsla(0, 100%, 100%, 1);
  }
  [lang="fr"] &::before {
    content: 'Photos de mannequins. Pour illustrer le texte.';
  }
}
