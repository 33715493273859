// Resource link
//
// Style guide: components.resource-link


@resource-img-offset: 0.35em;

.resource-link,
.resource-link:not(.u-no-pseudo) {
  position: relative;
  display: table;
  max-width: 24rem;
  margin: 0 0 0 @resource-img-offset;
  padding: 0;
  font-family: @fonts[heading];
  line-height: (@line-height[heading] + 0.1);
  font-weight: bold;
  background-color: @grey2;
  background-image: none;
  border: 1px solid @grey3;
  box-shadow: @box-shadow[transparent];
  transform: translateY(0);
  text-decoration: none;

  &:hover,
  &:focus {
    box-shadow: @box-shadow[basic];
    transform: translateY(-2px);
  }

  &::before,
  &::after,
  &[href$=".pdf"]::before,
  &[href$=".pdf"]::after {
    content: none;
  }

  &[href$=".pdf"] {
    .resource-link__image-wrapper {
      &::before {
        content: '';
      }
      &::after {
        content: '';
        background-image: url('@{image-path}icons/icon-download-white.svg');
        background-position: center;
      }
    }
  }
  &[target="_blank"] {
    .resource-link__image-wrapper {
      &::before {
        content: '';
      }
      &::after {
        content: '';
        background-image: url('@{image-path}icons/icon-external-white.svg');
        background-position: center;
        background-size: 1em;
      }
    }
  }

  small {
    display: block;
    margin-bottom: 0.1em;
    font-weight: normal;
  }
}
.resource-link--feature {
  font-size: 1.25em;
}
.resource-link__image-wrapper,
.resource-link__text {
  display: table-cell;
  vertical-align: top;
}
.resource-link__image-wrapper {
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: none;
    top: 2.04em;
    right: 1em;
    width: 1em;
    height: 1em;
    // the right-side positioning plus translateX ensures that this ribbon
    // won't show for browsers unable to display/rotate it properly
    transform: translateX(1em) rotate(45deg);
    transform-origin: top right;
    background-color: @grey-darker;
  }
  &::after {
    position: absolute;
    display: block;
    content: none;
    font-size: 0.9em;
    top: 0.6em;
    right: 0;
    width: 1.75em;
    height: 1.7em;
    box-shadow: @box-shadow[dark];
    color: white;
    background-color: @blue-dark;
    background-repeat: no-repeat;
    background-size: 1.2em;
  }
}
.resource-link__image {
  position: relative;
  display: block;
  width: 4.5em;
  min-height: 4.5em;
  margin: 0 0 @resource-img-offset;
  background-color: @grey-darker;
  background-size: cover;
  box-shadow: @box-shadow[sm];

  transform: translate(-@resource-img-offset, -@resource-img-offset);

  img {
    display: block;
  }
}
.resource-link__image--lg {
  width: 6em;
  min-height: 0;
}
.resource-link__text {
  padding: 0.5em 0.75em 0.75em 0.5em;
  width: 100%;
}
.resource-link__info {
  display: block;
  margin: 0.5em 0 0.5em;
  font-size: 0.7em;
  font-family: @fonts[body];
  font-weight: normal;
  font-style: italic;
  color: @grey-darker;
}
.resource-link__name {
  font-size: 1.25em;
}


.resource-link__image--c3p-programs {
  background-position: center;
  background-image: url('@{image-path}resource-images/c3p-programs.jpg');
}
.resource-link__image--c3p-resources-research {
  background-position: center;
  background-image: url('@{image-path}resource-images/c3p-resources-research.jpg');
}

// .resource-image-mixin(
  // @modifier;
  // @colour-light;
  // @colour-dark;
  // @bg-position;
  // @stop1: 35%;
  // @stop2: 60%;
  // @opacity1: 0.5;
  // @opacity2: 0.2
// ) {
  // .resource-link-image--@{modifier} {
    // background-color: @blue-dark;
    // background-color: hsl(@colour-dark);
    // background-position: @bg-position;
    // background-image:
      // linear-gradient(10deg, hsla(@colour-dark, @opacity1) @stop1, hsla(@colour-light, @opacity2) @stop2),
      // url('@{image-path}content/.jpg');
  // }
// }
//
// .resource-image-mixin(runaway; ~'19, 47%, 53%'; ~'19, 47%, 38%'; 75% center);

// add image disclaimer where necessary
// .resource-link-- {
  // &::before {
    // position: absolute;
    // content: 'Model in image. Intended as illustrative.';
    // left: 0.5rem;
    // bottom: 0.4em;
    // font-size: 0.4rem;
    // font-family: @fonts[body];
    // font-weight: normal;
    // color: @grey-dark;
    // opacity: 0.75;
  // }
  // [lang="fr"] &::before {
    // content: 'Photo d’un mannequin. Pour illustrer le texte.';
  // }
// }
