.social-links {
  display: flex;
  flex: 0 0 auto;
  margin: 0 0 1em;
  list-style: none;
  align-items: center;
}
.social-links__item {
  display: inline-block;
  margin-right: 0.75em;

  &:last-of-type {
    margin-right: 0;
  }
}
.social-links__link {
  .site-footer & {
    display: block;
    padding: 0.25em 0.3em;
    font-size: 1.5em;
    line-height: 1;

    &::after {
      content: none;
    }
  }
}
