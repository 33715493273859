// Docs and usage notes for form components/macros are at the end of this file

@form-colour: {
  border-static: @grey4;
  border-focused: black;
  outline-static: @grey4;
  outline-focused: @blue;
  checkmark: black;
  input-error: @red;
  input-success: @green;
}
// For use with the input[type="checkbox"] and input[type="radio"] elements —
// even though their opacity is set to 0 to allow for custom styling, their
// sizes should match what's visibly present.
// https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
.form-checkbox-size() {
  width: 1.2em;
  height: 1.2em;
}
@form-checkbox-fontsize: 1.5em;
@form-input-border-width: 2px;
@form-input-focus-size: 0.1875rem;



form {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
fieldset {
  display: block;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  border-width: 0;
}
fieldset:not(.form-group) {
  margin-top: 2rem;
}
legend:not(.label) {
  display: block;
  font-size: @hsize[h4];
  width: 100%;
  margin-bottom: @global-margin-bottom;
  padding-bottom: 0.125em;
  font-weight: bold;
  border-bottom: 0.125rem solid @line-grey;
}
.help-text,
.helper-text {
  font-size: 0.85em;
  margin: 0.25em 0 0.75em 0.1em;
  padding-left: 0.75em;
  color: @grey6;
  border-left: 4px solid @grey3;
}



// ------
// INPUTS


input,
textarea,
select {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  
  width: 100%;
  padding: 0.5em 0.6em;
  border: @form-input-border-width solid @form-colour[border-static];
  border-radius: @btn-border-radius;
  background-color: white;
  box-shadow: 0 0 0 0 @form-colour[outline-static];
  transition: border @t[basic], box-shadow @t[basic];
  
  &:focus {
    border-color: @form-colour[border-focused];
    box-shadow: 0 0 0 @form-input-focus-size @form-colour[outline-focused];
  }
}
// Get rid of the number "dial" buttons, they're ludicrously small hit targets.
// A better solution still is to never use number input types, and instead use
// text input types set up with its inputmode and pattern attributes set like so:
// <input type="text" inputmode="numeric" pattern="[0-9]*">
// https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
input[type="number"],
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  margin: 0;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}
input[type="date"],
input[type="time"] {
  background-color: white;
}
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  // Match width, height, font-size, and position of custom styling:
  font-size: @form-checkbox-fontsize;
  .form-checkbox-size();
  // top: 0.55rem;
  left: 1.15rem;
  opacity: 0;
}
input[type="search"] {
  -webkit-appearance: none;
}
.input--short {
  max-width: 18em;
}

select {
  padding-left: 0.35em;
  padding-right: 1.75em;
  white-space: nowrap;
  appearance: none;
  text-overflow: ellipsis;
  
  background-image: url('@{image-path}icons/icon-arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 1.2em;
  background-position: right 0.4em center;
  box-shadow: 0 0 0 0 @form-colour[outline-static];
  
  &::-ms-expand {
    display: none;
  }
  
  > option {
    font-family: inherit;
    line-height: inherit;
    
    margin: 0;
    padding: 0;
    -webkit-appearance: menulist-button;
  
    &[disabled]:not(:empty) {
      display: none;
    }
  }
}



// ---------------------------
// LABELS AND RELATED TRICKERY


label,
.label {
  position: relative;
  display: inline-block;
  margin-bottom: 0.25em;
  width: 100%;
  line-height: inherit;
  
  border-top: 0.125rem solid transparent; // for alignment
  
  // Custom styling for checkbox and radio inputs
  input[type="checkbox"] + &,
  input[type="radio"] + & {
    padding-left: 2.2em;
  }
  input[type="checkbox"] + &::before,
  input[type="radio"] + &::before {
    position: absolute;
    display: block;
    content: '';
    font-size: @form-checkbox-fontsize;
    .form-checkbox-size();
    // top: 0;
    // left: 0;
    top: -0.075em;
    left: -0.075em;
    background-color: white;
    border: @form-input-border-width solid @grey4;
    box-shadow: 0 0 0 0 @form-colour[outline-static];
    transition: border-color @t[basic], box-shadow @t[basic];
  }
  input[type="checkbox"]:focus + &::before,
  input[type="radio"]:focus + &::before {
    border-color: @form-colour[border-focused];
    box-shadow: 0 0 0 @form-input-focus-size @form-colour[outline-focused];
  }
  input[type="radio"] + &::before {
    border-radius: 50%;
  }
  
  input[type="checkbox"] + &::after,
  input[type="radio"] + &::after {
    position: absolute;
    display: block;
    content: '';
    opacity: 0;
    transition: opacity @t[snappy];
  }
  input[type="checkbox"]:checked + &::after {
    opacity: 1;
    // font-size: 0.9em;
    // font-size: @form-checkbox-fontsize;
    // top: -0.15em;
    // top: 0.3em;
    top: 0.08em;
    left: 0.45em;
    width: 0.7em;
    height: 1.2em;
    border: solid @form-colour[checkmark];
    border-width: 0 0.35em 0.35em 0;
    transform: rotate(45deg);
  }
  input[type="radio"]:checked + &::after {
    opacity: 1;
    // top: 0.2em;
    top: -0.075em;
    left: -0.075em;
    width: 1.2em;
    height: 1.2em;
    font-size: @form-checkbox-fontsize;
    border-radius: 50%;
    border: 0.3em solid transparent;
    background-clip: content-box;
    background-color: @form-colour[checkmark];
  }
  
  small {
    font-weight: normal;
    font-style: italic;
    color: @grey5;
  }
}
.label-radio {
  display: block;
  width: auto;
  padding-top: 0;
  text-align: left;
  
  input {
    width: auto;
    margin-right: 0.5em;
  }
}
.label-radio--inline {
  display: inline-block;
}
.label--lg {
  font-size: 1.15em;
  font-weight: bold;
}
.is-required::before {
  position: absolute;
  top: 0;
  left: -1.2ch;
  width: 1ch;
  content: '*';
  color: @form-colour[input-error];
}



// --------------
// GROUP WRAPPERS


.form-group {
  position: relative;
  // Large bottom margin:
  .u-mb-lg();
}
.form-group--inline-block {
  display: inline-block;
  width: auto;
}
.form-group--horiz-double {
  .input-group + .input-group {
    margin-top: 0.5em;
  }
}
.input-group {
  position: relative;
  display: inline-block;
  width: 100%;
}
.input-group--inline-btn {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: stretch;
  
  input {
    display: inline-block;
    flex: 0 1 auto;
    min-width: 0;
  }
  button {
    display: inline-block;
    min-height: 2.7em;
    min-height: calc(2.5em + 0.125rem);
    margin-left: 0.125rem;
    flex: 0 0 auto;
    
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
  }
}



// ---------------------
// STATE-RELATED STYLING


.form-error {
  display: block;
  font-size: 0.9rem;
  margin: 0.35em 0;
  font-style: italic;
  color: @form-colour[input-error];
  
  .dark-bg & {
    color: #fff;
  }
}
[data-field-valid="false"] {
  // color: @form-colour[input-error];

  input,
  input[type="checkbox"] + label::before,
  input[type="radio"] + label::before,
  textarea,
  select {
    border-color: @form-colour[input-error];
  }
}
[disabled] {
  cursor: not-allowed;
}



// -------------
// MEDIA QUERIES

@media screen and (min-width: 24em) {
  .form-group--horiz-sm {
    display: flex;
    
    .input-group,
    .select-wrapper {
      display: inline-block;
      vertical-align: top;
      width: 48%;
      flex: 1 0 auto;
    }
    label,
    .label {
      display: inline-block;
      vertical-align: top;
      width: 45%;
      flex: 0 1 6em;
      max-width: 6em;
      margin-right: 1em;
      padding-top: calc(0.4em + 0.125rem);
      text-align: right;
    }
  }
}

@media screen and (min-width: 35rem) {
  .btn-align {
    display: block;
  }
  .form-group--horiz-align {
    margin-left: 31%;
    margin-left: calc(30% + 1em);
  }
  
  .form-group--horiz,
  .form-group--horiz-double,
  .form-group--horiz-even,
  .form-group--horiz-radio {
    display: flex;
    
    label,
    .label {
      display: inline-block;
      vertical-align: top;
      margin-right: 1em;
      padding-top: calc(0.4em + 0.125rem);
      text-align: right;
    }
    .input-group {
      display: inline-block;
      vertical-align: top;
      flex: 1 0 auto;
    }
  }
  
  .form-group--horiz {
    label,
    .label {
      width: 30%;
      flex: 0 1 30%;
    }
    .input-group {
      width: 65%;
    }
    .input-group--inline-btn {
      display: inline-flex;
    }
  }
  .form-group--horiz-double {
    label,
    .label {
      width: 30%;
      flex: 0 1 30%;
    }
    .input-group {
      width: 31%;
      width: calc(32.5% - 0.5em);
    }
    .input-group + .input-group {
      margin-top: 0;
      margin-left: 1em;
    }
  }
  .form-group--horiz-even {
    label,
    .label {
      width: 48%;
      flex: 0 1 auto;
    }
    .input-group {
      width: 48%;
    }
  }
  .form-group--horiz-radio {
    .label {
      width: 30%;
      flex: 0 1 30%;
    }
    label {
      text-align: left;
      padding-top: 0;
    }
    .input-group {
      padding-top: 0.4em;
      width: 65%;
    }
  }
}



// Checkbox
//
// ### Using the checkbox macro
// The macro `custom_fields.checkbox()` accepts the following arguments:
// ```jinja2
// [% raw %][[ custom_fields.checkbox(
//   label,
//   id,
//   horiz=false,
//   required=true,
//   error_msg='',
//   helper_text='',
//   padding='md',
//   extra_classes=''
// ) ]][% endraw %]
// ```
//
// ### Example usage
// ```html
// [% raw %]<form data-controller="form--validation">
//   [[ custom_fields.checkbox(
//     'I acknowledge that this checkbox must be checked',
//     'checkbox-example1',
//     error_msg='Please check this checkbox.'
//   ) ]]
//   [[ custom_fields.checkbox(
//     'This one is no big deal, though <small>(optional)</small>',
//     'checkbox-example2',
//     required=false,
//     helper_text='It has some helper text'
//   ) ]]
// </form>[% endraw %]
// ```
//
// Markup:
// <form data-controller="form--validation">
//   <div class="form-group" data-field-container>
//     <div id="checkbox-example1-desc"></div>
//     <div class="input-group u-pl-md u-pt-sm">
//       <input type="checkbox" id="checkbox-example1"
//         aria-describedby="checkbox-example1-desc checkbox-example1-errors"
//         data-error-message="Please check this checkbox."
//         required>
//       <label for="checkbox-example1">
//         I acknowledge that this checkbox must be checked
//       </label>
//     </div>
//     <div id="checkbox-example1-errors" class="u-pl-md" data-error-container></div>
//   </div>
//   <div class="form-group" data-field-container>
//     <div id="checkbox-example2-desc"></div>
//     <div class="input-group u-pl-md u-pt-sm">
//       <input type="checkbox" id="checkbox-example2"
//         aria-describedby="checkbox-example2-desc checkbox-example2-errors">
//       <label for="checkbox-example2">
//         This one is no big deal, though <small>(optional)</small>
//       </label>
//     </div>
//     <div id="checkbox-example2-errors" class="u-pl-md" data-error-container></div>
//   </div>
// </form>
//
// Style guide: forms.checkbox


// Input
//
// ### Using the input macro
// The macro `custom_fields.input()` accepts the following arguments:
// ```jinja2
// [% raw %][[ custom_fields.input(
//   label,
//   id,
//   type='text',
//   horiz=false,
//   required=true,
//   error_msg='',
//   helper_text='',
//   input_attr='',
//   extra_classes=''
// ) ]][% endraw %]
// ```
//
// ### Example usage
// ```html
// [% raw %]<form data-controller="form--validation">
//   [[ custom_fields.input(
//     'Tell us where the incident happened:',
//     'input-example',
//     error_msg='This field is required.',
//     helper_text='On an app, website, messaging service, forum, chatroom, etc.',
//   ) ]]
// </form>[% endraw %]
// ```
//
// Markup:
// <form data-controller="form--validation">
//   <div class="form-group" data-field-container>
//     <label for="input-example">
//       Tell us where the incident happened:
//     </label>
//     <div class="input-group">
//       <div id="input-example-desc">
//         <p class="helper-text">On an app, website, messaging service, forum, chatroom, etc.</p>
//       </div>
//       <input type="text" id="input-example"
//         aria-describedby="input-example-desc input-example-errors"
//         data-error-message="This field is required."
//         required>
//       <div id="input-example-errors" data-error-container></div>
//     </div>
//   </div>
// </form>
//
// Style guide: forms.input


// Radio button
//
// ### Using the radio button macro
// The macro `custom_fields.radio()` accepts the following arguments:
// ```jinja2
// [% raw %][[ custom_fields.radio(
//   label,
//   id,
//   options,
//   horiz=false,
//   required=true,
//   error_msg='',
//   helper_text='',
//   padding='md',
//   fieldset_wrap=true,
//   extra_classes=''
// ) ]][% endraw %]
// ```
//
// ### Example usage
// ```html
// [% raw %]<form data-controller="form--validation">
//   [[ custom_fields.radio(
//     'Do you know where the incident happened?',
//     'radio-example',
//     [
//       { 'value': '1', 'label': 'Yes' },
//       { 'value': '0', 'label': 'No' },
//       { 'value': '2', 'label': 'I don’t know' },
//     ],
//     error_msg='Please select one of these options.'
//   ) ]]
// </form>[% endraw %]
// ```
//
// Markup:
// <form data-controller="form--validation">
//   <fieldset class="form-group">
//     <div class="u-mb-sm" aria-describedby="radio-example-desc radio-example-errors" data-field-container>
//       <legend class="label">
//         Do you know where the incident happened?
//       </legend>
//       <div id="radio-example-desc"></div>
//       <div class="input-group u-pl-md u-pt-sm">
//         <input type="radio"
//           id="radio-example_1"
//           name="radio-example"
//           value="1"
//           data-error-message="Please select one of these options."
//           required>
//         <label for="radio-example_1">Yes</label>
//       </div>
//       <div class="input-group u-pl-md u-pt-sm">
//         <input type="radio"
//           id="radio-example_0"
//           name="radio-example"
//           value="0"
//           data-error-message="Please select one of these options."
//           required>
//         <label for="radio-example_0">No</label>
//       </div>
//       <div class="input-group u-pl-md u-pt-sm">
//         <input type="radio"
//           id="radio-example_2"
//           name="radio-example"
//           value="2"
//           data-error-message="Please select one of these options."
//           required>
//         <label for="radio-example_2">I don’t know</label>
//       </div>
//       <div id="radio-example-errors" class="u-pl-md" data-error-container></div>
//     </div>
//   </fieldset>
// </form>
//
// Style guide: forms.radio-button


// Select
//
// The macro `custom_fields.select()` is set up to get enhanced with an autocomplete by default whenever possible.
//
// ### Using the select macro
// The macro `custom_fields.select()` accepts the following arguments:
// ```jinja2
// [% raw %][[ custom_fields.select(
//   label,
//   id,
//   horiz=false,
//   required=true,
//   error_msg='',
//   helper_text='',
//   autocomplete=true,
//   extra_classes=''
// ) ]][% endraw %]
// ```
//
// ### Example usage
// ```html
// [% raw %]<form data-controller="form--validation">
//   [% call custom_fields.select(
//     'Tell us where the incident happened:',
//     'select-example',
//     error_msg='This field is required.',
//     extra_classes='u-inputwidth-sm'
//   ) %]
//     [% for opt_value, opt_text in view_options %]
//     <option value="[[ opt_value ]]">[[ opt_text|safe ]]</option>
//     [% endfor %]
//   [% endcall %]
// </form>[% endraw %]
// ```
//
// Markup:
// <form data-controller="form--validation">
//   <div class="form-group u-inputwidth-sm" data-field-container>
//     <label for="select-example">
//       Tell us where the incident happened:
//     </label>
//     <div class="input-group"
//       data-controller="form--autocomplete"
//       data-form--autocomplete-selection-value=""
//       data-form--autocomplete-active-option-value="">
//       <div id="select-example-desc"></div>
//       <select id="select-example"
//         aria-describedby="select-example-desc select-example-errors"
//         data-error-message="This field is required."
//         data-form--autocomplete-target="select"
//         required>
//         <option value="">&nbsp;</option>
//         <option value="app">App</option>
//         <option value="website">Website</option>
//         <option value="msg">Messaging service</option>
//         <option value="forum">Forum</option>
//         <option value="chatroom">Chatroom</option>
//         <option value="other">Other</option>
//       </select>
//       <div id="select-example-errors" data-error-container></div>
//     </div>
//   </div>
// </form>
//
// Style guide: forms.select


// Textarea
//
// ### Using the textarea macro
// The macro `custom_fields.textarea()` accepts the following arguments:
// ```jinja2
// [% raw %][[ custom_fields.textarea(
//   label,
//   id,
//   horiz=false,
//   required=true,
//   cols='30',
//   rows='5',
//   error_msg='',
//   helper_text='',
//   extra_classes=''
// ) ]][% endraw %]
// ```
//
// ### Example usage
// ```html
// [% raw %]<form data-controller="form--validation">
//   [[ custom_fields.textarea(
//     'Tell us where the incident happened:',
//     'textarea-example',
//     error_msg='This field is required.',
//     helper_text='On an app, website, messaging service, forum, chatroom, etc.',
//   ) ]]
// </form>[% endraw %]
// ```
//
// Markup:
// <form data-controller="form--validation">
//   <div class="form-group" data-field-container>
//     <label for="textarea-example">
//       Tell us where the incident happened:
//     </label>
//     <div class="input-group">
//       <div id="textarea-example-desc">
//         <p class="helper-text">On an app, website, messaging service, forum, chatroom, etc.</p>
//       </div>
//       <textarea
//         id="textarea-example"
//         cols="30"
//         rows="5"
//         aria-describedby="textarea-example-desc textarea-example-errors"
//         data-error-message="This field is required."
//         required
//         ></textarea>
//       <div id="textarea-example-errors" data-error-container></div>
//     </div>
//   </div>
// </form>
//
// Style guide: forms.textarea
