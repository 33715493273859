// Blockquote
//
// Uses the same base styling mixin as callouts.
//
// Markup:
// <blockquote>
//   <p>A person's name is not the title of a work — even if people call that person a piece of work….</p>
//   <footer>
//     — <cite><a href="http://developers.whatwg.org/text-level-semantics.html#the-cite-element">HTML5 for Web Developers</a></cite>
//   </footer>
// </blockquote>
// <blockquote>
//   I cannot fix on the hour, or the spot, or the look or the words, which laid the foundation. It is too long ago.
//   I was in the middle before I knew that I had begun.
//   <footer>
//     — Jane Austen, <cite>Pride and Prejudice</cite>
//   </footer>
// </blockquote>
//
// Style guide: components.blockquote

blockquote {
  .callout-base(
    @padding: 1em 2.75em 1.25em 3em;
  );

  position: relative;
  background-color: @grey2;
  border-color: @grey3;

  &::before,
  &::after {
    position: absolute;
    display: block;
    line-height: 1;
    color: @blue;
  }

  &::before {
    content: '\201C';
    font-size: 5em;
    top: 0;
    left: 0.1em;
  }
  // &::after {
    // content: '\201D';
    // font-size: 2.5em;
    // bottom: 0.75em;
    // right: 0.4em;
  // }

  [lang="fr"] &::before {
    content: '\AB';
    font-size: 3.5em;
    top: -0.05em;
    font-weight: bold;
  }
  // [lang="fr"] &::after {
    // content: '\BB';
    // bottom: 1.5em;
    // font-size: 2em;
    // font-weight: bold;
  // }

  footer {
    font-size: 0.85em;
    margin-top: 0.85em;
    margin-left: 2em;
    padding-left: 1.25em;
    text-indent: -1.25em;
  }
}

.blockquote--plain {
  .callout-base();

  &::before,
  [lang="fr"] &::before,
  &::after {
    content: none;
  }
}
