// Article nav
//
// The article nav component provides links, usually at the end of an article, to the next and/or previous articles in the same collection
// or series, like the Cybertip.ca Alerts.
//
// At the moment, its macro is set up to handle and loop through queryset objects fed in through a page’s view. For example, the view
// `ctip_alert` in `app/views.py` renders a particular Cybertip Alert. The previous and next alerts that were posted get assigned to the
// variables `earlier_alert` and `later_alert`, and those are then used in the article nav macro as shown in the example below.
//
// ### Using the article nav macro
// The macro `m.article_nav()` accepts the following arguments:
// ```jinja2
// [% raw %][[ m.article_nav(
//   prev_article,
//   next_article,
//   prev_text,
//   next_text
// ) -]][% endraw %]
// ```
//
// * `prev_article`: **Required.**
// * `next_article`: **Required.**
// * `prev_text`: Optional. Defaults to `'Previous:'`.
// * `next_text`: Optional. Defaults to `'Next:'`.
//
// ### Example usage
// ```html
// [% raw -%]
// [[ m.article_nav(
//   later_alert,
//   earlier_alert,
//   prev_text='More recent alert:',
//   next_text='Earlier alert:',
// ) ]]
// [% endraw -%]
// ```
//
// Markup:
// <ul class="article-nav">
//   <li class="article-nav__item article-nav__item--prev">
//     <a class="article-nav__link article-nav__link--prev" href="#">
//       <small class="article-nav__direction-text">More recent alert:</small><br>
//       <span class="article-nav__title">What are “cappers” and why do parents need to know?</span>
//     </a>
//   </li>
//   <li class="article-nav__item article-nav__item--next">
//     <a class="article-nav__link article-nav__link--next" href="#">
//       <small class="article-nav__direction-text">Earlier alert:</small><br>
//       <span class="article-nav__title">Safety Concerns with Cake — Live Stream Video Chat app</span>
//     </a>
//   </li>
// </ul>
//
//
// Style guide: components.article-nav

.article-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 3em 0 0;
  list-style: none;
  border-top: 1px solid @line-grey;
}
.article-nav__item {
  margin: 0;
  flex: 1 0 100%;
  border: solid @line-grey;
  border-width: 0;

  &:not(:empty) {
    padding: 0.35em;
    border-width: 0 0 1px;
  }
  &:last-of-type {
    border-width: 0;
  }
}
.article-nav__item--prev {
  text-align: right;
}
.article-nav__link {
  position: relative;
  display: block;
  padding: 0.5em 1.2em 0.6em;
  text-decoration: none;

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 1.25em;
    height: 1.25em;
    top: 2.2em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('@{image-path}icons/icon-arrow-up.svg');
  }
}
.article-nav__link--prev {
  padding-left: 2.5em;

  &::after {
    content: '';
    left: 0.2em;
    transform: rotate(270deg);
  }
}
.article-nav__link--next {
  padding-right: 2.5em;

  &::after {
    content: '';
    right: 0.2em;
    transform: rotate(90deg);
  }
}
.article-nav__direction-text {
  font-style: italic;
}
.article-nav__title {
  text-decoration: underline;
  text-decoration-color: hsla(204, 100%, 30%, 0.6);
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 2px;

  .article-nav__link:hover > &,
  .article-nav__link:focus > &,
  .article-nav__link:active > & {
    text-decoration-color: @blue-dark;
  }
}



@supports (mask-image: none) {
  .article-nav__link::after {
    content: none;
    background-image: none;
    mask-image: url('@{image-path}icons/icon-arrow-up.svg');
    mask-repeat: no-repeat;
    mask-position: center right;
    mask-size: contain;
    background-color: currentColor;
  }
  .article-nav__link--prev::after {
    content: '';
    transform: rotate(270deg);
  }
  .article-nav__link--next::after {
    content: '';
    transform: rotate(90deg);
  }
}



@media (min-width: 30em) {
  .article-nav {
    flex-wrap: nowrap;
  }
  .article-nav__item {
    flex: 1 0 50%;
    margin-right: -1px;
    border-width: 0 1px 0 0;

    &:not(:empty) {
      padding: 0.35em;
      border-width: 0 1px 0 0;
    }
    &:last-of-type {
      margin-right: 0;
      border-width: 0;
    }
  }
}
