.timeline {
  margin-left: 0.5em;
  padding-left: 1em;
  border-left: 0.125em solid @line-grey;
}
.timeline__year {
  position: relative;
}
.timeline__node {
  position: relative;
  margin: 2em 0 4em;
  padding-bottom: 0.5em;
}
.timeline__node--year {
  .h1();
  margin: 1.5em 0 1em;
  padding-bottom: 0;
  color: @blue;
}
.timeline__dot {
  position: absolute;
  display: block;
  width: 1.35rem;
  height: 1.35rem;
  top: 0.4em;
  left: -1.7rem;
  border: 0.25em solid white;
  border-radius: 50%;
  background-color: @blue;
}
.timeline__dot--year {
  width: 1.75rem;
  height: 1.75rem;
  top: 0.35em;
  left: -1.9rem;
  border-width: 0.08em;
  background-color: @blue-dark;
}
.timeline__node-heading {
  margin-top: 0.75em;
  color: @blue-dark;
}
.timeline__media {
  max-width: 40em;
}



@media screen and (min-width: 40em) {
  .timeline {
    margin-left: 1em;
    padding-left: 2em;
  }
  .timeline__dot {
    left: -2.7rem;
  }
  .timeline__dot--year {
    left: -2.9rem;
  }
}



@supports (position: sticky) {
  .timeline {
    margin-left: 1em;
    padding: 1em 0 0 1.75em;
  }
  .timeline__dot {
    display: none;
  }
  .timeline__node {
    margin: 2em 0 3em;

    > :last-child {
      margin-bottom: 0;
    }
  }
  .timeline__node--year {
    position: sticky;
    display: inline-block;
    width: auto;
    z-index: 2;
    top: 1rem;
    margin: 0 0 1em;
    padding: 0 0.2em;
    transform: rotate(-90deg) translateY(-0.25em) translateX(-1em);
    transform-origin: bottom left;
    background-color: white;
  }
  .timeline__node:first-of-type:not(.timeline__node--year) {
    margin-top: -5em;
  }



  @media screen and (min-width: 30em) {
    .timeline {
      padding-left: 2.5em;
    }
    .timeline__node--year {
      transform: rotate(-90deg) translateY(-0.5em) translateX(-1em);
    }
  }
  @media screen and (min-width: 40em) {
    .timeline {
      margin-left: 2em;
      padding-left: 4em;
    }
    .timeline__year {
      margin-bottom: 3em;
    }
    .timeline__node--year {
      transform: translateX(-50%);
      font-size: 3.5em;
      margin: 0 0 0.25em -4rem;
    }
    .timeline__node:first-of-type:not(.timeline__node--year) {
      margin-top: -3.75em;
    }
  }
}
